export default {
  id: '05759a2aac',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page">\r\n    <div class="navbar no-shadow paymentPage">\r\n        <div class="navbar-bg"></div>\r\n        <div class="navbar-inner">\r\n            <div class="left">\r\n            <a href="#" class="link back ripple-color-red">\r\n                <img class="" src="assets/icons/back.png" width="35"/>\r\n            </a>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div class="page-content">\r\n        <div class="block_title">\r\n            <div class="reg-txt-content">\r\n                <h2 class="margin-left fw-bold">Payment history!</h2>\r\n                <h3 class="margin-left fw-bold fs-12">Track all payment of investments, renewals</h3>\r\n            </div>\r\n            <div class="block__preloader text-align-center" style="margin-top: 250px;">\r\n                <div class=\'loader\'>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                </div>\r\n            </div>\r\n            <div class="card card-main card__payment-history-no hidden" style="margin-top: 50px;">\r\n                <div class="card-content text-align-center">\r\n                    <img src="assets/icons/no-docs.png" style="margin-top: 150px; height: 100px;" />\r\n                    <div class="item-title text-color-blue-light fw-600 fs-16 margin-top">No payment history found!</div>\r\n                </div>\r\n            </div>\r\n            <div class="list media-list no-hairlines no-chevron no-hairlines-between">\r\n                <ul class="media-list-payment-history">\r\n                    \r\n                </ul>\r\n            </div>\r\n        </div>\r\n    </div>\r\n  ';
      return r;
    }(this);
  },
  styleScoped: false
};