export default {
  id: 'f9fa9bc400',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page form-page">\r\n    <div class="page-content">\r\n        <div class="block text-align-center">\r\n            <div class="block-loader text-align-center" style="margin-top: 250px;">\r\n                <div class=\'loader\'>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--dot\'></div>\r\n                    <div class=\'loader--text\'></div>\r\n                </div>\r\n            </div>\r\n            <div class="block-paymentST">\r\n            </div>\r\n        </div>\r\n        <div class="text-align-center" style="position: fixed; bottom: 0">\r\n            <h3 class="fw-bold fs-14 text-color-gray">Please do not back or close the this page during payment verification.</h3>\r\n        </div>\r\n    </div>\r\n  ';
      return r;
    }(this);
  },
  styleScoped: false
};