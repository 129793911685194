import $$ from 'dom7';

import HomePage from '../pages/home.f7.html';
import LoginPage from '../pages/login.f7.html';
import RegisterPage from '../pages/register.f7.html';
import PaymentPage from '../pages/payment.f7.html';
import FormPage1 from '../pages/form1.f7.html';
import BusinessLoanPage from '../pages/businessloan.f7.html';
import FormPage2 from '../pages/form2.f7.html';
import FormPage4 from '../pages/form4.f7.html';
import BeneficiaryPage from '../pages/beneficiary.f7.html';
import loanStatusPage from '../pages/status.f7.html';
import creditscorePage from '../pages/creditScore.f7.html';
import notificationPage from '../pages/notification.f7.html';
import paymentSuccessPage from '../pages/paymentSuccess.f7.html';
import kycPage from '../pages/kyc.f7.html';
import investmentPage from '../pages/investment.f7.html';
import investmentformPage from '../pages/invest-form.f7.html';
import investmentdashboardPage from '../pages/investment-dashboard.f7.html';
import investmentdetailsPage from '../pages/investment-details.f7.html';
import paymenthistoryPage from '../pages/payment-history.f7.html';
import investcertificatePage from '../pages/invest-certificate.f7.html';
import transactionsPage from '../pages/transactions.f7.html';
import walletPage from '../pages/wallet.f7.html';
import bankPage from '../pages/bank.f7.html';
import withdrawPage from '../pages/withdraw.f7.html';
import renewalPage from '../pages/renewal-details.f7.html';
import NotFoundPage from '../pages/404.f7.html';
import party from "party-js";
import DeviceDetector from "device-detector-js";
import { jsPDF } from "jspdf";
import 'animate.css';
const app_version = '1.1.5';
var baseURL = "https://ptfapi.rbsapi.co.in/v2/";
const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});
const INR = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 0,
});
const INR2 = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 0,
  currencyDisplay: "code"
});
var routes = [
  {
    path: '/',
    component: HomePage,
    name: 'Home',
    on: {
      pageInit: function (e, page) {
        var app = this.app;
        app.dialog.progress('Loading...');
        //check if updateLogs is true
        var updateLogs = localStorage.getItem('updateLogsVer') || [];
        //check if updateLogs is []
        if(updateLogs.length != 0) {
          updateLogs = JSON.parse(updateLogs);
          if(updateLogs.app_version == app_version && updateLogs.updateLogs == true) {
            app.popup.close('.app-update--logs');
          } else {
            app.popup.open('.app-update--logs');
          }
        } else {
          app.popup.open('.app-update--logs');
        }
        $$('.updateLogs-watched').on('click',function(){
          localStorage.setItem('updateLogsVer', JSON.stringify({app_version: app_version, updateLogs: true}));
          app.popup.close('.app-update--logs');
          //check if authToken is set in localStorage
          if(localStorage.getItem('authToken') !== null) {
            try {
              var value = localStorage.getItem('authToken');
              var parsedValue = JSON.parse(value);
              return Array.isArray(parsedValue);
            } catch (error) {
              app.methods.refreshUI('Session expired, please login to continue!');
              var router = app.views.current.router;
              router.navigate(router.currentRoute.url, {
                reloadCurrent: true,
                ignoreCache: true,
              });
            }
          } else {

          }
        });
        //get helpline Number
        app.methods.getSettings(function(data) {
          $$('.helpLinenumber').html(data.help);
          $$('.helpLinenumberhref').attr('href','tel:'+data.help);
        });
        //swiper slider
        var swiper = app.swiper.create('.swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 0,
          centeredSlides: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        });
        app.methods.getUserData(function(data) {
          app.dialog.close();
          if(data.isLoggedin == 'true') {
            app.dialog.close();
            $$('.profile-name').html(data.name);
            $$('.profile-email').html(data.email);
            $$('.profile-phone').html('+91-'+data.phone);
            $$('.profile-address').html(data.address);
            $$('.profile-dob').html(data.dob);
            $$('.profile-aadhaar').html(data.aadhaar);
            if(data.kycData[0].is_aadhaar == 1) {
              $$('.item-aadhaar-verify').empty();
              $$('.item-aadhaar-verify').append('<span class="fs-12 text-color-green"><i class="icon f7-icons text-color-green lh-1" style="font-size: 16px;">checkmark_seal</i> Verified</span>');
            }
            if(data.kycData[0].is_pan == 1) {
              $$('.item-pan-verify').empty();
              $$('.item-pan-verify').append('<span class="fs-12 text-color-green"><i class="icon f7-icons text-color-green lh-1" style="font-size: 16px;">checkmark_seal</i> Verified</span>');
            }
            if(data.kycData[0].is_bank == 1) {
              $$('.item-bank-verify').empty();
              $$('.item-bank-verify').append('<span class="fs-12 text-color-green"><i class="icon f7-icons text-color-green lh-1" style="font-size: 16px;">checkmark_seal</i> Verified</span>');
            }
            $$('.profile-pan').html(data.pan);
            $$('.profile-bname').html(data.bname);
            $$('.profile-ac').html(data.ac);
            $$('.profile-ifsc').html(data.ifsc);
            $$('.welcome__msg').removeClass('hidden');
            $$('.login-link').attr('href','#');
            $$('.login-link').addClass('logout');
            $$('.login-link').html('Logout');
            $$('.loggedinAvatar').attr('src', 'https://ui-avatars.com/api/?name='+data.name+'&background=faf0ff&rounded=true&color=292467&bold=true')
            if(data.loanStatus == true) { //generate loan status
              $$('.emi-link').attr('href','#');
              $$('.emi-link').addClass('popup-open');
              $$('.emi-link').attr('data-popup','#emi-popup');
              $$('.cart-link').remove();
              app.methods.getUserDataLoan(function(data) {
                if(data.applied == true && data.approve != true) {
                  $$('.card__applyLoanLink').attr('href','/form1/');
                  $$('.card__applyLoanLinkBusiness').attr('href','/businessloan/');
                  $$('.linkCreditScore').attr('href','/creditScore/');
                }
                if(data.applied == true && data.approve == true) {
                  $$('.card__applyLoanLink').attr('href','/form1/');
                  $$('.card__applyLoanLinkBusiness').attr('href','/businessloan/');
                  $$('.linkCreditScore').attr('href','/creditScore/');
                }
              })
            } else {
              $$('.emi-link').attr('href','#');
              $$('.emi-link').addClass('popup-open');
              $$('.emi-link').attr('data-popup','#emi-popup');
              $$('.card__applyLoanLink').attr('href','/form1/');
              $$('.card__applyLoanLinkBusiness').attr('href','/businessloan/');
              $$('.linkCreditScore').attr('href','/creditScore/');
            }
            //check if invested or not
            app.methods.getUserDataInvest(
              function(data) {
                if(data.isInvested == true) {
                  $$('.card__investment').attr('href','/investment-dashboard/');
                } else {
                  $$('.card__investment').attr('href','/investment/');
                }
              }
            );
            //if device is cordova device
            var uuid = app.methods.getDeviceUUID();
            const deviceDetector = new DeviceDetector();
            const userAgent = navigator.userAgent;
            if(app.device.cordova) {
              var deviceData = {
                cordova: device.cordova,
                model: device.model,
                platform: device.platform,
                uuid: device.uuid,
                version: device.version,
                manufacturer: device.manufacturer,
                isVirtual: device.isVirtual,
                serial: device.serial,
                sdkVersion: device.sdkVersion
              };
            } else {
              var deviceData = deviceDetector.parse(userAgent);
            }
            var deviceInfo = {
              'uuid': uuid,
              'device': deviceData,
              'app_version': app_version
            }
            app.methods.deviceSave(deviceInfo);
          }
          else if(data.isLoggedin == 'false' && data.status == 'unauthorized') {
            app.dialog.close();
            app.methods.refreshUI(data.message);
            // app.toast.show({
            //   text: data.message,
            //   closeTimeout: 3000,
            // });
          } else {
            
          }
        });
        //init awiper
        var mySwiper = app.swiper.create('.swiper-container', {
          autoplay: {
            delay: 5000,
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'preloaderbar',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      }
    }
  },
  {
    path: '/login/',
    component: LoginPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'true') {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        $$('.lgNum').on('click',function(){
          if (!$$('#userLogin')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            login();
          }
        });
        //user login
        function login() {
          var formdata = $('#userLogin').serialize();
          var data = formdata+'&checkLogin=true';
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            data,
            'json',
            function() { app.dialog.progress('Loading...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.setItem('phone', data.phone);
                app.popup.open('.sheet-OTP--verification');
                $$('.sheet-OTP--verification--phonenumber').html('xxxxxxx'+data.phone.slice(-3));
                if($$('#username').val() != '2226590157') {
                  app.methods.autoOTPcordova();
                }
                //reset form
                $$('#userLogin')[0].reset();
              }
              else if(data.code == 'deactivated') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else if(data.code == 'notverified') {
                app.popup.open('.sheet-OTP--verification');
                $$('.sheet-OTP--verification--phonenumber').html('xxxxxxx'+data.phone.slice(-3));
                app.methods.autoOTPcordova();
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //forget password
        $(document).off('click', '.fpBTN');
        $(document).on('click','.fpBTN',function(){
          if (!$$('#fp-username')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            Forgetpassword();
          }
        });
        function Forgetpassword() {
          var phone = $$('#fp-username').val();
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            {forgetPass: true, mobile: phone},
            'json',
            function() {app.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                app.popup.close('.sheet-forget--pass');
                app.popup.open('.sheet-forget--otp');
                localStorage.setItem('phone', data.phone);
                $$('.sheet-forget--otp--phonenumber').html('xxxxxxx'+data.phone.slice(-3));
                //reset phone field
                $$('#fp-username').val('');
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //change password
        $(document).off('click', '.fpBTNotp');
        $(document).on('click','.fpBTNotp',function(e){
          e.preventDefault();
          if (!$$('#changePass')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            changePass();
          }
        });
        function changePass() {
          var formdata = $('#changePass').serialize();
          var data = formdata+'&phone='+localStorage.getItem('phone')+'&changePass=true';
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            data,
            'json',
            function() {app.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                app.popup.close('.sheet-forget--otp');
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
                //reset form
                $$('#changePass')[0].reset();
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
      }
    }
  },
  {
    path: '/register/',
    component: RegisterPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'true') {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //income masking
        $$('input.income').keyup(function(event) {
          var val = $(this).val().replace(/[₹ ,]/g,'');
          $$('input#income').val(val);
          // skip for arrow keys
          if(event.which >= 37 && event.which <= 40){
           event.preventDefault();
          }
          $(this).val(function(index, value) {
              value = value.replace(/,/g,'');
              if(value.indexOf('₹') > -1) {
                return app.methods.addComma(value);
              }
              else {
                return '₹ '+app.methods.addComma(value);
              }
          });
        });
        var calendarDefault = app.calendar.create({
          inputEl: '#dob',
          closeOnSelect: true,
          dateFormat: 'dd/mm/yyyy',
          openIn: 'customModal',
          header: true,
          footer: true,
        });
        //user registration
        $$('.btnSignup').on('click',function(){
          if (!$$('#userSignup')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            signup();
          }
        });
        //user signup
        function signup() {
          var formdata = $('#userSignup').serialize();
          var data = formdata+'&userSignup=true';
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            data,
            'json',
            function() { app.dialog.progress('Loading...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.setItem('phone',data.phone);
                app.popup.open('.sheet-OTP--verification');
                $$('.sheet-OTP--verification--phonenumber').html('xxxxxxx'+data.phone.slice(-3));
                app.methods.autoOTPcordova();
                //reset form
                $$('#userSignup')[0].reset();
              }
              else if(data.code == 'exist') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
      }
    }
  },
  {
    path: '/payment/:type/:refID',
    component: PaymentPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var refID = page.route.params.refID;
        console.log(page.route.params.type);
        app.sheet.close('.paymentDetails__sheetModal');
        app.methods.getUserData(
          function(data) {
            app.dialog.close();
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            } else {
              if(page.route.params.type == 'loan') {
                app.dialog.confirm(formatter.format(data.maxLoan)+' will be credited to your account ' +data.ac+ ' after buying the finance card. Do you want to continue?', 'Confirm Payment?', function() {
                  //scroll to .block__upi--payment
                  document.getElementById("upi__content").scrollIntoView();
                });
                $$('.block-phonepe').addClass('hidden');
                $$('.block-manual').removeClass('hidden');
                $$('.badge-phonepe').html('Unavailable');
                $$('.item-radio-phonepe').addClass('disabled');
                $$('.item-radio-upi input[name=payment-options-radio]').prop('checked', true);
                $$('.block__upi--payment').removeClass('hidden');
                app.methods.getUserDataLoan(
                  function(data) {
                    $$('.block__preloader').addClass('hidden');
                    $$('.ul__investmentPay').empty();
                    $$('.ul__investmentPay').append('<li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Finance Card Charges ('+data.cardName+')</b>  </div>\
                        <div class="item-after text-color-blue-light fs-14 fw-600">'+formatter.format(data.cardFees)+'</div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Sub Total</b> </div>\
                        <div class="item-after text-color-blue-light fs-24 fw-600">'+formatter.format(data.cardFees)+'</div>\
                      </div>\
                    </div>\
                  </li>');
                });
                app.methods.getBank(
                  function(data) {
                    //check if it's upi or bank in data
                    var upi = data.data.find(element => element.type === 'UPI');
                    $$('.upiQR').attr('src', upi.upi_qrIMG);
                    $$('.upi__id').html('UPI ID: '+upi.upi_id);
                    app.methods.getUserDataLoan(
                      function(data) {
                        var cardFees = data.cardFees;
                        //generate UPI intent
                        var upiLink = 'upi://pay?pa='+upi.upi_id+'&pn='+upi.bank+'&am='+cardFees+'&cu=INR';
                        $$('.upiIntent').attr('href', upiLink);
                    });
                });
              }
              if(data.payment_invalid == 1) {
                $$('.payment-invalilabel').removeClass('hidden');
              }
            }
          }
        );
        //if payment for investment
        if(page.route.params.type == 'investment' || page.route.params.type == 'renewal') {
          $$('.paymentPG').removeClass('disabled');
          $$('.ul__investmentPay').empty();
          //get investment payment details
          app.methods.getUserDataInvest(
            function(data) {
              app.dialog.close();
              if(data.isInvested == true) {
                //match refID
                const investmentData = data.investmentData.find(element => element.refID === refID);
                if(page.route.params.type == 'investment') {
                  var subTotal = +investmentData.amount + +investmentData.renewalAmount;
                  $$('.block__preloader').addClass('hidden');
                  $$('.ul__investmentPay').empty();
                  $$('.ul__investmentPay').append('<li>\
                  <div class="item-content">\
                    <div class="item-inner">\
                      <div class="item-title text-color-blue-light fs-12 fw-500"> <b>Investment</b> </div>\
                      <div class="item-after text-color-blue-light fs-14 fw-600">'+formatter.format(investmentData.amount)+'</div>\
                    </div>\
                  </div>\
                </li>\
                <li>\
                  <div class="item-content">\
                    <div class="item-inner">\
                      <div class="item-title text-color-blue-light fs-12 f-500"> <b>Renewal for 1 month</b>  </div>\
                      <div class="item-after text-color-blue-light fs-14 fw-600">'+formatter.format(investmentData.renewalAmount)+'</div>\
                    </div>\
                  </div>\
                </li>\
                <li>\
                  <div class="item-content">\
                    <div class="item-inner">\
                      <div class="item-title text-color-blue-light fs-12 f-500"> <b>Payable Amount</b> </div>\
                      <div class="item-after text-color-blue-light fs-24 fw-600">'+formatter.format(subTotal.toFixed(2))+'</div>\
                    </div>\
                  </div>\
                </li>');
                } else {
                  var subTotal = + +investmentData.renewalAmount;
                  $$('.block__preloader').addClass('hidden');
                  $$('.ul__investmentPay').empty();
                  $$('.ul__investmentPay').append('<li>\
                  <div class="item-content">\
                    <div class="item-inner">\
                      <div class="item-title text-color-blue-light fs-12 f-500"> <b>Renewal for 1 month</b>  </div>\
                      <div class="item-after text-color-blue-light fs-14 fw-600">'+formatter.format(investmentData.renewalAmount)+'</div>\
                    </div>\
                  </div>\
                </li>\
                <li>\
                  <div class="item-content">\
                    <div class="item-inner">\
                      <div class="item-title text-color-blue-light fs-12 f-500"> <b>Payable Amount</b> </div>\
                      <div class="item-after text-color-blue-light fs-24 fw-600">'+formatter.format(subTotal.toFixed(2))+'</div>\
                    </div>\
                  </div>\
                </li>');
                }
              } else {
                app.views.main.router.navigate({name: 'Home'});
              }
            }
          );
        }
        //if phonepe is active then
        app.methods.getAPI(function(data) {
          data.forEach(val => {
            if(val.name == 'phonepe' && val.value == '1') {
              $$('.block-phonepe').removeClass('hidden');
              $$('.block-manual').addClass('hidden');
            }
            if(val.name == 'phonepe' && val.value == '0') {
              $$('.block-phonepe').addClass('hidden');
              $$('.block-manual').removeClass('hidden');
              $$('.badge-phonepe').html('Unavailable');
              $$('.item-radio-phonepe').addClass('disabled');
              $$('.item-radio-bank input[name=payment-options-radio]').prop('checked', true);
            }
          });
        });
        //check if payment method is checked
        $$(document).on('change', '#payment-options-radio', function() {
          var paymentMethod = $$('input[name=payment-options-radio]:checked' ).val();
          if(paymentMethod == 'phonepe') {
            $$('.block-phonepe').removeClass('hidden');
            $$('.block-manual').addClass('hidden');
          } else {
            $$('.block-phonepe').addClass('hidden');
            $$('.block-manual').removeClass('hidden');
            if(paymentMethod == 'bank') {
              $$('.block__bank--payment').removeClass('hidden');
              $$('.block__upi--payment').addClass('hidden');
            } else {
              $$('.block__upi--payment').removeClass('hidden');
              $$('.block__bank--payment').addClass('hidden');
            }
          }
        });
        $$('.copy__upi').on('click', function() {
          var upiID = $$('.upi__id').html();
          upiID = upiID.split(': ')[1];
          var textArea = document.createElement("textarea");
          textArea.value = upiID;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
          app.toast.show({
            text: 'UPI ID copied!',
            closeTimeout: 3000,
          });
        });
        //submit payment for phonepe
        $$('.paymentPG').on('click', function() {
          if(app.device.cordova) {
            var redirectURL = 'https://localhost/#!/';
          } else {
            var redirectURL = 'https://app.phonetouchfinance.in/paymentdemo/#!/';
          }
          var token = localStorage.getItem('authToken');
          if(page.route.params.type == 'investment') {
            var formData = {initiatePayment:true, token:token, redirectURL:redirectURL, refID:refID, type:'investment', uuid:app.methods.getDeviceUUID()};
          } else if(page.route.params.type == 'renewal') {
            var formData = {initiatePayment:true, token:token, redirectURL:redirectURL, refID:refID, type:'renewal', uuid:app.methods.getDeviceUUID()};
          } else {
            var formData = {initiatePayment:true, token:token, redirectURL:redirectURL, type:'card', uuid:app.methods.getDeviceUUID()};
          }
          //formdata base64 encode
          var params = btoa(JSON.stringify(formData));
          document.location = 'https://phonetouchfinance.in/payment/data/'+params;
          // app.methods.request(
          //   'https://phonetouchfinance.in/api/v1/payment/',
          //   formData,
          //   'json',
          //   function() {
          //     app.dialog.progress('Processing payment...');
          //   },
          //   function(data) {
          //     app.dialog.close();
          //     if(data.status == 'success') {
          //       document.location = data.url;
          //     }
          //     else {
          //       app.toast.show({
          //         text: data.message,
          //         closeTimeout: 3000,
          //       });
          //     }
          //   },
          //   function() {
          //     app.dialog.close();
          //     app.toast.show({
          //     text: 'Something went wrong!',
          //     closeTimeout: 3000,
          //   });
          //   }
          // );
        });
        //payment submit
        $$('#payment').on('change', function() {
          var paymentIMG = $$('#payment').prop('files')[0];
          var formData = new FormData();
          formData.append('paymentForm', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('paymentIMG', paymentIMG);
          formData.append('uuid', app.methods.getDeviceUUID());
          if(page.route.params.type == 'investment') {
            var url = baseURL+'investment.php';
            formData.append('isInvest', true);
            formData.append('refID', refID);
          } else if(page.route.params.type == 'renewal') {
            var url = baseURL+'investment.php';
            formData.append('isRenewal', true);
            formData.append('refID', refID);
          } else {
            var url = baseURL+'ajaxKYC.php';
          }
          var file = this.files[0];
          var fileType = file['type'];
          var validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/JPEG", "image/PNG", "image/JPG"];
          
          if(!$$('#payment')[0].checkValidity()) {
            app.toast.show({
              text: 'Please upload your payment screenshot or receipt!',
              closeTimeout: 3000,
            });
          }
          else if ($.inArray(fileType, validImageTypes) < 0) {
            app.toast.show({
              text: 'This is not an Image!',
              closeTimeout: 3000,
            });
          }
          else {
            app.methods.post(
              url,
              formData,
              'json',
              function() {
                app.dialog.progress('Uploading...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  app.toast.show({
                    text: data.msg,
                    closeTimeout: 3000,
                  });
                  if(page.route.params.type == 'investment' || page.route.params.type == 'renewal') {
                    var view = app.views.current;
                    view.router.navigate('/payment-history/'+refID+'/all',{reloadCurrent: true, ignoreCache: true});
                  } else {
                    var view = app.views.current;
                    view.router.back('/',{force:true});
                  }
                }
                else {
                  app.toast.show({
                    text: data.msg,
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                app.dialog.close();
                app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
              }
            );
          }
        });
      }
    }
  },
  {
    path: '/form1/',
    component: FormPage1,
    name: 'form1',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //range slider
        $$('.amount-range').on('range:change', function (e, range) {
          //var num = '₹' + range.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var num = formatter.format(range.value);
          $$('.laon__amount').html(num);
          $$('.applyLoan').attr('data-amount', range.value);
          if(range.value >= 50000 && range.value <= 150000) {
            $$('.laon__amountTEN').html('6 - 12 Months');
          } else if(range.value >= 150000 && range.value <= 250000) {
            $$('.laon__amountTEN').html('6 - 18 Months');
          } else if(range.value >= 250000 && range.value <= 350000) {
            $$('.laon__amountTEN').html('6 - 24 Months');
          } else {
            $$('.laon__amountTEN').html('6 - 36 Months');
          }
        });
        $$('#agree_checkbox').on('change', function() {
          var is_agreeChecked = document.getElementById("agree_checkbox").checked;
          if(is_agreeChecked == false) {
            $$('.applyLoan').addClass('disabled');
          } else {
            $$('.applyLoan').removeClass('disabled');
          }
        
        });
        $$('.applyLoan').off('click');
        $$('.applyLoan').on('click',function(){
          var amount = $(this).attr('data-amount');
          localStorage.setItem('maxloan', amount);
          console.log(amount);
          app.views.main.router.navigate('/form2/');
        })
      }
    }
  },
  {
    path: '/businessloan/',
    component: BusinessLoanPage,
    name: 'businessloan',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        //range slider
        $$('.amount-range').on('range:change', function (e, range) {
          //var num = '₹' + range.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var num = formatter.format(range.value);
          $$('.laon__amount').html(num);
          $$('.applyLoan').attr('data-amount', range.value);
          if(range.value >= 50000 && range.value <= 150000) {
            $$('.laon__amountTEN').html('6 - 12 Months');
          } else if(range.value >= 150000 && range.value <= 250000) {
            $$('.laon__amountTEN').html('6 - 18 Months');
          } else if(range.value >= 250000 && range.value <= 350000) {
            $$('.laon__amountTEN').html('6 - 24 Months');
          } else {
            $$('.laon__amountTEN').html('6 - 36 Months');
          }
        });
        // $$('#amount').on('keyup', function() {
        //   var range = app.range.get('.amount-range');
        //   var value = $$('#amount').val();
        //   range.setValue(value);
        // });
        // $$('#amount').on('focusout', function() {
        //   var val = $$('#amount').val();
        //   if(val < 50000) {
        //     $$('#amount').val(50000);
        //   }
        //   else if (val > 500000) {
        //     $$('#amount').val(500000);
        //   }
        // });
        $$('.applyLoan').off('click');
        $$('.applyLoan').on('click',function(){
          var amount = $(this).attr('data-amount');
          localStorage.setItem('maxloan', amount);
          console.log(amount);
          app.views.main.router.navigate('/form4/');
        })
      }
    }
  },
  {
    path: '/form2/',
    component: FormPage2,
    name: 'form2',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        //check if applied or not
        app.methods.getUserDataLoan(function(data) {
          if(data.applied == true) {
            $$('.KYC__form').addClass('hidden');
            $$('.is__KYClist').removeClass('hidden');
          }
        })
        //emi calculator
        var currEMIval = document.querySelector('input[name="tenure"]:checked').value;
        computeLoan(currEMIval);
        function computeLoan(tenure) {
          var intRate = 13;
          var amount = localStorage.getItem('maxloan');
          var tenure = tenure;
          var r = intRate / (12 * 100);
          var emi = ((amount * r * Math.pow(1 + r, tenure)) /  
                        (Math.pow(1 + r, tenure) - 1)).toFixed(2);
          $$('.monthlyEMI').html(formatter.format(Math.round(emi)));
        }
        $$(document).on('change','input[name="tenure"]',function(){
          var tenure = this.value;
          computeLoan(tenure);
        });
        //check kyc
        app.methods.getUserData(function(data) {
          if(data.isLoggedin == 'true') {
            //filter API on/off
            app.methods.getAPI(function(apidata) {
              //temp
              apidata.forEach(val => {
                if(val.name == 'pan' && val.value == '0') {
                  //remove pan validation
                  $$('.form2-panVerifyBtn').addClass('hidden');
                  $$('.panVerifyLable').addClass('hidden');
                  $$('#panVerify').val('true');
                  $$('.panVerifyAttempts').empty();
                }
                if(val.name == 'pan' && val.value == '1') {
                  $$('.panVerifyAttempts').html(3 - data.kycData[0].pan_attempt+' attempts left');
                }
              });
            });
            
            if(data.kycData[0].is_pan == 1) {
              $$('.panVerifyAttempts').empty();
              $$('.form2-panVerifyBtn').empty();
              $$('.form2-panVerifyBtn').addClass('disabled');
              $$('.panVerifyLable').empty();
              $$('.kyc__ver2').removeClass('hidden');
              $$('#panVerify').val('true');
              $$('#panNum').attr('readonly', true);
              $$('#panNum').val(data.kycData[0].pan_temp);
              $$('.item-input-pan').addClass('item-input-focused item-input-with-value');
            }
            if(data.kycData[0].is_pan == 0 && data.kycData[0].is_fail_pan == 'error_invalid_pan' && data.kycData[0].pan_attempt >= 3) {
              $$('.form2-panVerifyBtn').empty();
              $$('.form2-panVerifyBtn').addClass('disabled');
              $$('.panVerifyLable').empty();
              $$('.panVerifyLable').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>We kindly request you to contact us for updating your PAN card information along with the required proof. \
                After the update, please return at your convenience to complete the KYC verification process.</span>');
              $$('.kyc__ver3').removeClass('hidden');
              $$('#panNum').attr('readonly', true);
              $$('#panNum').val(data.kycData[0].pan_temp);
              $$('.item-input-pan').addClass('item-input-focused item-input-with-value');
            }
            if(data.kycData[0].is_pan == 0 && data.kycData[0].is_fail_pan == 'name_not_matched' && data.kycData[0].pan_attempt >= 3) {
              $$('.form2-panVerifyBtn').empty();
              $$('.form2-panVerifyBtn').addClass('disabled');
              $$('.panVerifyLable').empty();
              $$('.panVerifyLable').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
              <span>Kindly update your name in the profile settings to match your PAN card details. Once updated, please return to complete your KYC process.</span>');
              $$('.kyc__ver4').removeClass('hidden');
              $$('#panNum').attr('readonly', true);
              $$('#panNum').val(data.kycData[0].pan_temp);
              $$('.item-input-pan').addClass('item-input-focused item-input-with-value');
            }
          }
          if(data.isLoggedin == 'false' && data.status == 'blocked') {
            app.views.main.router.navigate({name: 'Home'});
          }
          if(data.isLoggedin == 'false') {
            app.views.main.router.navigate({name: 'Home'});
          }
        });
        //pan verification
        $$('.form2-panVerifyBtn').on('click',function(){
          if (!$$('#panNum')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyPAN();
          }
        });
        function verifyPAN() {
          var pan = $('#panNum').val();
          var data = 'pan='+pan+'&panVerify=true&panVerifyLevel=application&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying PAN Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.responseKey == 'success_pan') {
                $$('.panVerifyAttempts').empty('');
                $$('.form2-panVerifyBtn').empty();
                $$('.form2-panVerifyBtn').addClass('disabled');
                $$('.panVerifyLable').empty();
                $$('.kyc__ver2').removeClass('hidden');
                $$('#panVerify').val('true');
                $$('#panNum').attr('readonly', true);
                $$('#panNum').val(data.pan);
                $$('.item-input-pan').addClass('item-input-focused item-input-with-value');
              }
              else if(data.responseKey == 'error_invalid_pan') {
                $$('.panVerifyAttempts').empty('');
                $$('.form2-panVerifyBtn').empty();
                $$('.form2-panVerifyBtn').addClass('disabled');
                $$('.panVerifyLable').empty();
                $$('.panVerifyLable').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                  <span>We kindly request you to contact us for updating your PAN card information along with the required proof. \
                  After the update, please return at your convenience to complete the KYC verification process.</span>');
                $$('.kyc__ver3').removeClass('hidden');
                $$('#panNum').attr('readonly', true);
                $$('#panNum').val(data.pan);
                $$('.item-input-pan').addClass('item-input-focused item-input-with-value');
                app.toast.show({
                  text: 'The provided PAN card is invalid.',
                  closeTimeout: 3000,
                });
              }
              else if(data.errorcode == 'name_not_matched') {
                $$('.panVerifyAttempts').empty('');
                $$('.form2-panVerifyBtn').empty();
                $$('.form2-panVerifyBtn').addClass('disabled');
                $$('.panVerifyLable').empty();
                $$('.panVerifyLable').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>Kindly update your name in the profile settings to match your PAN card details. Once updated, please return to complete your KYC process.</span>');
                $$('.kyc__ver4').removeClass('hidden');
                $$('#panNum').attr('readonly', true);
                $$('#panNum').val(data.pan);
                $$('.item-input-pan').addClass('item-input-focused item-input-with-value');
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //get ifsc
        $$('#ifsc').on('keyup', function() {
          $$('.bankBranchName').html('');
          $$('#branch').val('');
          if($$('#ifsc').val().length == 11) {
            $.getJSON('https://ifsc.razorpay.com/'+$$('#ifsc').val(), function(data) {
              $$('.bankBranchName').removeClass('hidden');
              $$('.bankBranchName').html(data.BRANCH);
              $$('#branch').val(data.BRANCH);
              $$('#bankname').val(data.BANK);
              $$('.item-input-bank').addClass('item-input-focused');
              $$('.item-input-bank').addClass('item-input-with-value');
            });
          }
        })
        //input validation
        $$('.form2-submit').on('click', function() {
          var amount = localStorage.getItem('maxloan');
          var tenure = document.querySelector('input[name="tenure"]:checked').value;
          var pan = $$('#panNum').val();
          var panVerify = $$('#panVerify').val();
          var bankAcc = $$('#bankacc').val();
          var IFSC = $$('#ifsc').val();
          var aadhaar = $$('#aadhaar').val();
          var bankname = $$('#bankname').val();
          var branch = $$('#branch').val();
          var formData = new FormData();
          
          formData.append('submit-application', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('amount', amount);
          formData.append('tenure', tenure);
          formData.append('aadhaar', aadhaar);
          formData.append('pan', pan);
          formData.append('verify2', panVerify);
          formData.append('bankacc', bankAcc);
          formData.append('bankname', bankname);
          formData.append('ifsc', IFSC);
          formData.append('branch', branch);
          formData.append('uuid', app.methods.getDeviceUUID());
          var error = 0;
          for (var value of formData.values()) {
            if(value == '' || value == 'undefined') {
              error += 1;
            }
          }
          if(error == 0) {
            app.methods.post(
              baseURL+'ajaxKYC.php',
              formData,
              'json',
              function() {
                $$('.button').addClass('disabled');
                app.dialog.progress('Processing...');
              },
              function(data) {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.dialog.close();
                if(data.status == 'success') {
                  app.popup.open('.sheet-loan--applied');
                  $$('.sheet-loan--applied__title').html('Your Personal Loan application for '+formatter.format(data.amount)+' has been submitted.');
                  $$('.sheet-loan--applied__text').html('Your application is under verification. Contact us for any loan application queries. Your application reference number is: '+data.ref);
                  localStorage.setItem('amount', data.amount);
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else if(data.errorcode == 'applied') {
                  app.popup.open('.sheet-loan--applied');
                  $$('.sheet-loan--applied__title').html('You have already submitted your application!');
                  $$('.sheet-loan--applied__text').html('Your application is under verification. Contact us for any loan application queries. Your application reference number is: '+data.ref);
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else {
                  app.toast.show({
                    text: 'Something went wrong, please try again',
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
              }
            );
          }
          else {
            for (var pair of formData.entries()) {
              console.log(pair[0]+ ', ' + pair[1]); 
            }
            $$('#form2')[0].checkValidity();
            $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'Please complete all the required fields!',
                closeTimeout: 3000,
              });
          }
        });
      }
    }
  },
  {
    path: '/form4/',
    component: FormPage4,
    name: 'form4',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        // initialize box-scope
        var boxes = document.querySelectorAll('.box');

        for(let i = 0; i < boxes.length; i++) {
          let box = boxes[i];
          // app.methods.initDropEffect(box);
          app.methods.initImageUpload(box);
        }
        //check if applied or not
        app.methods.getUserDataLoan(function(data) {
          if(data.applied == true) {
            $$('.is__KYClist').removeClass('hidden');
          }
        });
        //emi calculator
        computeLoan(6);
        function computeLoan(tenure) {
          var intRate = 11.99;
          var amount = localStorage.getItem('maxloan');
          var tenure = tenure;
          var r = intRate / (12 * 100);
          var emi = ((amount * r * Math.pow(1 + r, tenure)) /  
                        (Math.pow(1 + r, tenure) - 1)).toFixed(2);
          $$('.monthlyEMI').html(formatter.format(emi));
        }
        $$(document).on('change','#tenure',function(){
          var tenure = this.value;
          computeLoan(tenure);
        });
        //filter API on/off
        app.methods.getAPI(function(data) {
          data.forEach(val => {
            if(val.name == 'aadhaar' && val.value == '0') {
              //remove aadhaar validation
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('true');
            }
            if(val.name == 'pan' && val.value == '0') {
              //remove pan validation
              $$('.panVerifyBtn').addClass('hidden');
              $$('.panVerifyLable').addClass('hidden');
              $$('#panVerify').val('true');
            }
            if(val.name == 'bank' && val.value == '0') {
              $$('.bankVerifyBtn').addClass('hidden');
              $$('.bankVerifyLable').addClass('hidden');
              $$('#bankVerify').val('true');
            }
          });
        });
        //change aadhaarVerifyLable
        $$(document).on('change','#kycDoc', function(){
          var doc = this.value;
          var isVerify = $$('#aadhaarVerify').val();
          console.log(isVerify);
          if(isVerify != true) {
            if(doc == 'Voter ID') {
              $$('.aadhaarVerifyLable').html('Please verify your Voter ID and make sure your registered name is the same as on your Voter ID.');
              $$('.aadhaarVerifyBtn').attr('data-doc','voter');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('true');
            } else if(doc == 'Driving License') {
              $$('.aadhaarVerifyLable').html('Please verify your Driving License and make sure your registered name and date of birth is the same as on your Driving License.');
              $$('.aadhaarVerifyBtn').attr('data-doc','driving');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('true');
            } else if(doc == 'Passport') {
              $$('.aadhaarVerifyLable').html('Please verify your Passport');
              $$('.aadhaarVerifyBtn').attr('data-doc','passport');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('true');
            } else {
              $$('.aadhaarVerifyLable').html('Please verify your Aadhaar card via UIDAI and make sure your phone number is linked with your Aadhaar.');
              $$('.aadhaarVerifyBtn').attr('data-doc','aadhaar');
            }
          }
        });
        //verify aadhaar card
        $$('.aadhaarVerifyBtn').on('click',function(){
          if (!$$('#aadhaar')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            var doc = $(this).attr("data-doc");
            if(doc == 'aadhaar') {
              verifyAadhaar();
            } else if(doc == 'voter') {
              verifyVoter();
            } else {
              verifyDriving();
            }
          }
        });
        //aadhaar verification
        function verifyAadhaar() {
          var aadhaar = $('#aadhaar').val();
          var data = 'aadhaar='+aadhaar+'&aadhaarVerify=true';
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Aadhaar Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status_code == '200') {
                localStorage.setItem('client_id', data.data.client_id);
                app.popup.open('.sheet-OTP--aadhaar');
              }
              else if(data.status_code == '422') {
                app.toast.show({
                  text: 'Invalid Aadhaar Card',
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '429') {
                app.toast.show({
                  text: 'Wait 45 seconds to generate OTP for same Aadhaar Number',
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '500') {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify otp for aadhaar
        $$('.adhOTPbtn').on('click',function(){
          if (!$$('#adh-otp')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyAadhaarOTP();
          }
        });
        function verifyAadhaarOTP() {
          var otp = $('#adh-otp').val();
          var data = 'otp='+otp+'&client_id='+localStorage.getItem('client_id')+'&aadhaarVerifyOTP=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying OTP...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status_code == '200') {
                localStorage.removeItem('client_id');
                app.popup.close('.sheet-OTP--aadhaar');
                $$('.aadhaarVerifyBtn').empty();
                $$('.aadhaarVerifyLable').empty();
                $$('.kyc__ver1').removeClass('hidden');
                $$('#aadhaarVerify').val('true');
              }
              else if(data.status_code == '422') {
                app.toast.show({
                  text: 'Invalid OTP',
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '500') {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
              else if(data.errorcode == 'name_not_matched') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //voter verification
        function verifyVoter() {
          var aadhaar = $('#aadhaar').val();
          var data = 'voter='+aadhaar+'&voterVerify=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Voter ID...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status_code == '200') {
                $$('.aadhaarVerifyBtn').empty();
                $$('.aadhaarVerifyLable').empty();
                $$('.kyc__ver1').removeClass('hidden');
                $$('#aadhaarVerify').val('true');
              }
              else if(data.status_code == '422') {
                app.toast.show({
                  text: 'Invalid Voter ID',
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '500') {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
              else if(data.errorcode == 'name_not_matched') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '429') {
                app.toast.show({
                  text: 'Wait 45 seconds to generate another request',
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify otp for aadhaar
        $$('.panVerifyBtn').on('click',function(){
          if (!$$('#panNum')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyPAN();
          }
        });
        function verifyPAN() {
          var pan = $('#panNum').val();
          var data = 'pan='+pan+'&panVerify=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying PAN Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status_code == '200') {
                $$('.panVerifyBtn').empty();
                $$('.panVerifyLable').empty();
                $$('.kyc__ver2').removeClass('hidden');
                $$('#panVerify').val('true');
              }
              else if(data.status_code == '422') {
                app.toast.show({
                  text: 'Invalid Pan Card',
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '500') {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '429') {
                app.toast.show({
                  text: 'Wait 45 seconds to generate another request',
                  closeTimeout: 3000,
                });
              }
              else if(data.errorcode == 'name_not_matched') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify bank account
        $$('.bankVerifyBtn').on('click',function(){
          if (!$$('#bankacc')[0].checkValidity() && !$$('#ifsc')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyBank();
          }
        });
        function verifyBank() {
          var account_no = $('#bankacc').val();
          var ifsc = $('#ifsc').val();
          var data = 'account_no='+account_no+'&bankVerify=true+&ifsc='+ifsc+'&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Bank Account...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status_code == '200') {
                $$('.bankVerifyBtn').empty();
                $$('.bankVerifyLable').empty();
                $$('.bankVerifyBtn').addClass('hidden');
                $$('.kyc__ver3').removeClass('hidden');
                $$('#bankVerify').val('true');
              }
              else if(data.status_code == '422') {
                app.toast.show({
                  text: 'Invalid Bank Account',
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '500') {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
              else if(data.status_code == '429') {
                app.toast.show({
                  text: 'Wait 45 seconds to generate another request',
                  closeTimeout: 3000,
                });
              }
              else if(data.errorcode == 'name_not_matched') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //get ifsc
        $$('#ifsc').on('keyup', function() {
          $$('.bankBranchName').html('');
          $$('#branch').val('');
          if($$('#ifsc').val().length == 11) {
            $.getJSON('https://ifsc.razorpay.com/'+$$('#ifsc').val(), function(data) {
              $$('.bankBranchName').removeClass('hidden');
              $$('.bankBranchName').html('Branch: '+data.BRANCH);
              $$('#branch').val(data.BRANCH);
            });
          }
        })
        //input validation
        $$('.form4-submit').on('click', function() {
          var amount = localStorage.getItem('maxloan');
          var tenure = $$('#tenure').val();
          var addimg1 = $$('#addressimg1').prop('files')[0];
          var addimg2 = $$('#addressimg2').prop('files')[0];
          var pan = $$('#panNum').val();
          var panimg = $$('#panimg').prop('files')[0];;
          var bankAcc = $$('#bankacc').val();
          var IFSC = $$('#ifsc').val();
          var aadhaar = $$('#aadhaar').val();
          var bankname = $$('#bankname').val();
          var docType = $$('#kycDoc').val();
          var branch = $$('#branch').val();
          var passbook = $$('#passbook').prop('files')[0];
          var selfie = $$('#selfie').prop('files')[0];
          //business details
          var businessName = $$('#busName').val();
          var businessType = $$('#busType').val();
          var businessAddress = $$('#busAddress').val();
          var aadhaarVerify = $$('#aadhaarVerify').val();
          var panVerify = $$('#panVerify').val();
          var bankVerify = $$('#bankVerify').val();
          if($$('#gstnum').val()) {
            var gst = $$('#gstnum').val();
          } else {
            var gst = null;
          }
          if($$('#compPan').val()) {
            var compPan = $$('#compPan').val();
          } else {
            var compPan = null;
          }
          if($$('#companyPan').prop('files')[0]) {
            var companyPan = $$('#companyPan').prop('files')[0];
          } else {
            var companyPan = null;
          }
          var formData = new FormData();
          
          formData.append('form4', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('amount', amount);
          formData.append('tenure', tenure);
          formData.append('aadhaar', aadhaar);
          formData.append('docType', docType);
          formData.append('addressimg1', addimg1);
          formData.append('addressimg2', addimg2);
          formData.append('pan', pan);
          formData.append('panimg', panimg);
          formData.append('bankacc', bankAcc);
          formData.append('bankname', bankname);
          formData.append('ifsc', IFSC);
          formData.append('branch', branch);
          formData.append('passbook', passbook);
          formData.append('selfie', selfie);
          formData.append('businessName', businessName);
          formData.append('businessType', businessType);
          formData.append('businessAddress', businessAddress);
          formData.append('gst', gst);
          formData.append('compPan', compPan);
          formData.append('companyPan', companyPan);
          formData.append('verify1', aadhaarVerify);
          formData.append('verify2', panVerify);
          formData.append('verify3', bankVerify);
          var error = 0;
          for (var value of formData.values()) {
            if(value == '' || value == 'undefined') {
              error += 1;
            }
          }
          if(error == 0) {
            app.methods.post(
              baseURL+'ajaxKYC.php',
              formData,
              'json',
              function() {
                $$('.button').addClass('disabled');
                app.dialog.progress('Loading...');
              },
              function(data) {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.dialog.close();
                if(data.status == 'success') {
                  app.popup.open('.sheet-loan--applied');
                  $$('.sheet-loan--applied__title').html('You\'ve submitted KYC for a Business Loan of '+formatter.format(localStorage.getItem('maxloan')));
                  $$('.sheet-loan--applied__text').html('Please wait while we\'re verifying your information, you can conatct with us if you have any queries regarding your loan application.');
                  localStorage.setItem('amount', data.amount);
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else if(data.errorcode == 'applied') {
                  app.popup.open('.sheet-loan--applied');
                  $$('.sheet-loan--applied__title').html('You have already submitted your documents, please check your Loan Status!');
                  $$('.sheet-loan--applied__text').html('Please wait while we\'re verifying your information, you can conatct with us if you have any queries regarding your loan application.');
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else {
                  app.toast.show({
                    text: 'Something went wrong, please try again',
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
              }
            );
          }
          else {
            $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'Please complete all the required steps!',
                closeTimeout: 3000,
              });
          }
          
        });
      }
    }
  },
  {
    path: '/beneficiary/',
    component: BeneficiaryPage,
    name: BeneficiaryPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        $$('.payment-block').hide();
        $$('.list__transferDet').empty();
        app.dialog.progress('Loading...');
        app.methods.getUserDataLoan(
          function(data) {
            $$('.card_type').html(data.cardName);
            var last = data.cardnumber.replace(/\d(?=\d{4})/g, "x");
            var val = last;
            var newval = '';
            val = val.replace(/\s/g, '');
            for(var i = 0; i < val.length; i++) {
                if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                newval = newval.concat(val[i]);
            }
            if(data.loanType == 'Personal Loan') {
              $$('.membership__cardTitle').html(data.cardName);
              $$('.card1').removeClass('hidden');
              $$('.pan').html(newval);
              $$('.name-on-card').html(data.name);
              $$('.date-from').html(data.validFrom);
              $$('.date-to').html(data.validTo);
              if(data.cardName === 'MoneyFlex Silver') {
                $$('.card-type').html('Silver');
                $$('.card-benefits').empty();
                $$('.card-benefits').append('<li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Valids for 1 Year.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i>  Maximum Loan Tenure for 12 Months.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Personal Loan offers from multiple Banks and NBFC.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Dedicated support and consulting for 1 Year.</li>\
                <li><i class="icon f7-icons text-color-red-deep fs-16" >xmark_circle</i> No discount on Loan Processing Fee.</li>\
                <li><i class="icon f7-icons text-color-red-deep fs-16" >xmark_circle</i> No Loan Protection Insurance.</li>');
              }
              else if(data.cardName === 'MoneyFlex Gold') {
                $$('.card-type').html('Gold');
                $$('.card-benefits').empty();
                $$('.card-benefits').append('<li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Valids for 2 Years.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i>  Maximum Loan Tenure for 24 Months.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Personal Loan offers from multiple Banks and NBFC.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Dedicated support and consulting for 2 Years.</li>\
                <li><i class="icon f7-icons text-color-red-deep fs-16" >xmark_circle</i> No discount on Loan Processing Fee.</li>\
                <li><i class="icon f7-icons text-color-red-deep fs-16" >xmark_circle</i> No Loan Protection Insurance.</li>');
              }
              else if(data.cardName === 'MoneyFlex Platinum') {
                $$('.card-type').html('Platinum');
                $$('.card-benefits').empty();
                $$('.card-benefits').append('<li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Valids for 3 Years.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i>  Maximum Loan Tenure for 36 Months.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Personal Loan offers from multiple Banks and NBFC.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Dedicated support and consulting for 3 Years.</li>\
                <li><i class="icon f7-icons text-color-red-deep fs-16" >xmark_circle</i> No discount on Loan Processing Fee.</li>\
                <li><i class="icon f7-icons text-color-red-deep fs-16" >xmark_circle</i> No Loan Protection Insurance.</li>');
              } else {
                $$('.card-type').html('Crown');
                $$('.card-benefits').empty();
                $$('.card-benefits').append('<li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Valids for 5 Years.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i>  Maximum Loan Tenure for 60 Months.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Personal Loan offers from multiple Banks and NBFC.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Dedicated support and consulting for 5 Years.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> 10% discount on Loan Processing Fee.</li>\
                <li><i class="icon f7-icons text-color-green fs-16" >checkmark_alt_circle</i> Loan Protection Insurance.</li>');
              }
            } else {
              $$('.membership__cardTitle').html(data.cardName);
              $$('.card2').removeClass('hidden');
              $$('.pan').html(newval);
              $$('.name-on-card').html(data.name);
              $$('.date-from').html(data.validFrom);
              $$('.date-to').html(data.validTo);
            }
            $$('.block__card').removeClass('hidden');
            if(data.order == '1') {
              $$('.finance_card__h2').html('My Finance Card!');
              $$('.finance_card__desc').addClass('hidden');
              $$('.toggle-list').removeClass('hidden');
            }
            //show hide card number
            $$('.checkbox-toggle').on('change', function(){
              var is_toggled = document.getElementById("checkbox-toggle").checked;
              console.log(is_toggled);
              if (is_toggled) {
                var val = data.cardnumber;
                var newval = '';
                val = val.replace(/\s/g, '');
                for(var i = 0; i < val.length; i++) {
                    if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                    newval = newval.concat(val[i]);
                }
                if(data.loanType == 'Personal Loan') {
                  $$('.pan').html(newval);
                  $$('.pan').attr('data-gold',newval);
                } else {
                  $$('.pan').html(newval);
                  $$('.pan').attr('data-silver',newval);
                }
              } else {
                var last = data.cardnumber.replace(/\d(?=\d{4})/g, "x");
                var val = last;
                var newval = '';
                val = val.replace(/\s/g, '');
                for(var i = 0; i < val.length; i++) {
                    if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                    newval = newval.concat(val[i]);
                }
                if(data.loanType == 'Personal Loan') {
                  $$('.pan').html(newval);
                  $$('.pan').attr('data-gold',newval);
                } else {
                  $$('.pan').html(newval);
                  $$('.pan').attr('data-silver',newval);
                }
              }
            })
            var toggle = app.toggle.create({
              el: '.toggle2',
              on: {
                change: function () {
                  if(toggle.checked == true) {
                  } else {
                  }
                }
              }
            });
            if(data.order != '1') {
              //api status
              app.methods.getAPI(function(dataAPI) {
                var payment_url = '/payment/loan/'+data.applicationRef;
                $$('.list__payProcessing').empty();
                $$('.list__payProcessing').append('<p class="text-color-blue-light fs-16 fw-600">Joining Fee</p>\
                <p class="text-color-blue-light fw-600"><span class="fs-24">₹'+data.cardFees+'</span><span class="fs-12"> + Applicable Taxes</span></p>\
                <p class="text-color-blue-light fs-12 fw-500">'+data.gst_txt+'</p>\
                <p class="text-color-blue-light fs-12 fw-500">Please read <a href="https://phonetouchfinance.in/cards-tc" style="text-decoration: underline; text-decoration-style: dashed;" class="link external" target="_system">T&C for Finance Card</a></p>\
                <a href="'+payment_url+'" style="margin-top: 25px;" class="col button button-fill button-large button-fill bg-color-red text-color-white margin-top btn-alt">Unlock Finance Card</a>');
              });
              
            } else {
              $$('.is_active__lable').removeClass('hidden');
              $$('.is_invoice__lable').removeClass('hidden');
              $$('.is_invoice__lable').attr('href','https://phonetouchfinance.in/invoice/id/'+data.applicationRef)
              $$('.block__kyc').removeClass('hidden');
            }
            app.dialog.close();
          }
        );
        $$('input[name="lnINS"]').change(function() {
          console.log(this.checked);
          if(this.checked) {
            $$('.payment-block').show();
            $$('.page-content').scrollTop(10000, 400);
          } else {
            $$('.payment-block').hide();
          }
        });
        //kyc
        $$('.complete-kyc').on('click', function() {

        });
        
      }
    }
  },
  {
    path: '/status/',
    component: loanStatusPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.dialog.progress('Loading...');
        app.methods.getUserData(function(data) {
          if(data.isLoggedin == 'true') {
            app.dialog.close();
            var userData = data;
            if(data.loanStatus == true) { //generate loan status
              app.methods.getUserDataLoan(function(data) {
                $$('.list__loanacnum').html(data.applicationRef);
                $$('.list__loantype').html(data.loanType);
                $$('.list__loanstamnt').html(formatter.format(data.loanAmnt));
                $$('.list__loansttenure').html(data.tenure +' Months');
                if( data.emi != null) {
                  var emi = data.emi;
                }
                $$('.list__loanstemi').html(formatter.format(emi));
                $$('.list__loanstfees').html('₹'+data.fees);
                $$('.list__loanstrate').html(data.rateofinterest+'%');
                if(data.applied == true && data.approve != true) {
                  $$('.creditStore').empty();
                  $$('.creditStore').append('<div class="block text-align-center">\
                    <img src="assets/icons/kyc-success.svg" alt="" style="margin-top: 0px; width: 80%">\
                    <div style="white-space: normal;" class="text-align-center text-color-blue-light fs-600 sheet-loan--applied__title">Under Review!</div>\
                    <div style="white-space: normal;" class="item-title item-credit-txt text-align-center fs-600 fs-12">Your Personal Loan application for '+formatter.format(data.loanAmnt)+' has been submitted.</div>\
                    <div class="item-title item-credit-txt" style="margin-top:25px;"><span class="">Your application is under verification. Contact us for any loan application queries. Your application reference number is: '+data.applicationRef+'</span></div>\
                  </div>');
                }
                if(data.applied == true && data.rejected == true) {
                  $$('.creditStore').empty();
                  $$('.creditStore').append('<div class="block text-align-center">\
                    <img src="assets/icons/warning.png" alt="" style="margin-top: 0px; width: 50%">\
                    <div style="white-space: normal;" class="text-align-center text-color-red-deep fs-600 sheet-loan--applied__title">Application Rejected!</div>\
                    <div class="item-title item-credit-txt text-color-blue-light" style="margin-top:25px;"><span class="">Your loan application with reference ID# '+data.applicationRef+' has been rejected. Please reapply in 90 days to check your eligibility.</span></div>\
                  </div>');
                }
                if(data.applied == true && data.approve == true) {
                  $$('.creditStore').empty();
                  if(data.order == '1') {
                    var pulse_class_kyc = "pulse";
                    var card_step = '<div class="step step-active">\
                    <div>\
                      <div class="circle"><i class="icon f7-icons text-color-green fs-20">checkmark_alt_circle_fill</i></div>\
                    </div>\
                    <div>\
                      <div class="title">Finance Card Unlocked</div>\
                      <div class="caption">'+data.cardName+'</div>\
                      <a href="/beneficiary/" class="button button-small bg-color-red text-color-white fs-12 btn-shine">Card details</a>\
                    </div>\
                    </div>';
                    $$('.application_progress_st__txt').html('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i> Thank you for showing interest in our loan services. \
                    Before our lenders can provide you with personalized loan offers, we kindly request you to complete your KYC (Know Your Customer) verification.');
                    $$('.application_progress_st__btn').html('Complete KYC');
                    $$('.application_progress_st__btn').attr('href', '/kyc/');
                  } else {
                    //hide progress
                    $$('.block-tracker').addClass('hidden');
                    $$('.block-tracker-title').empty();
                    $$('.creditStore').empty();
                    $$('.creditStore').append('<div class="block text-align-center">\
                    <div class="check-container">\
                        <div class="check-background">\
                            <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">\
                                <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />\
                            </svg>\
                        </div>\
                        <div class="check-shadow"></div>\
                    </div>\
                    <div style="white-space: normal;" class="text-align-center text-color-green fw-600 fs-24 animate__animated animate__heartBeat">Congratulations!</div>\
                    <div class="text-align-justify margin-top item-title item-credit-txt text-color-blue-light info-ptf display-flex">\
                    <img src="assets/icons/announce.png" height="35"/>\
                    <span class="margin-left">Your application has been approved. However, \
                    please be aware that this is not a Loan Approval. Our team will continue to process your application with our lenders, \
                    and further verification steps may be necessary to finalize the approval process.</span></div>');
                    //api status
                    app.methods.getAPI(function(data) {
                      data.forEach(val => {
                        if(val.name == 'bank' && val.value == '0') {
                          $$('.widthdrwal-block').removeClass('hidden');
                          $$('.maxLoanamount').html(formatter.format(userData.maxLoan));
                          $$('.item-bank-verify').empty();
                          $$('.block-error-txt-bank').empty();
                          $$('.block-error-txt-bank').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                            <span>Our Bank verification system is currently undergoing maintenance. Please revisit at a later time. We apologize for any inconvenience this may cause.</span>');
                        }
                        if(val.name == 'bank' && val.value == '1') {
                          $$('.list-bank-verify').removeClass('hidden');
                        }
                      });
                    });
                    
                    $$('.profile-bank').html(data.account);
                    $$('.profile-bankac').html(userData.bname);
                    $$('.final-step').addClass('hidden');
                    //bank verification
                    if(userData.kycData[0].is_bank == 1) {
                      //show withdrwal
                      $$('.widthdrwal-block').removeClass('hidden');
                      $$('.maxLoanamount').html(formatter.format(userData.maxLoan));
                      $$('.item-bank-verify').empty();
                      $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-green-light text-color-green"><i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified</span>');
                    }
                    if(userData.kycData[0].is_fail_bank == 'name_not_matched' && userData.kycData[0].is_bank == 0) {
                      $$('.item-bank-verify').empty();
                      $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update name</span>');
                      $$('.block-error-txt-bank').empty();
                      $$('.block-error-txt-bank').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                        <span>Kindly update your name in the profile settings to match your Bank account details. Once updated, please return to complete your Bank verification process.</span>');
                    }
                    if(userData.kycData[0].is_fail_bank == 'error_invalid_bank' && userData.kycData[0].is_bank == 0) {
                      $$('.item-bank-verify').empty();
                      $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Invalid Bank Account</span>');
                      $$('.block-error-txt-bank').empty();
                      $$('.block-error-txt-bank').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                        <span>We kindly request you to contact us for updating your Bank Account information along with the required proof. After the update, please return at your convenience to complete the Bank verification process.</span>');
                    }
                    //verify bank account
                    $$('.bankVerify').on('click',function(){
                      verifyBank();
                    });
                    function verifyBank() {
                      var data = 'bankVerify=true&token='+localStorage.getItem('authToken');
                      app.methods.request(
                        baseURL+'ajaxKYC.php',
                        data,
                        'json',
                        function() { app.dialog.progress('Verifying Bank Account...'); $$('.button').addClass('disabled'); },
                        function(data) {
                          $$('.button').removeClass('disabled');
                          app.dialog.close();
                          if(data.responseCode == 'S00000') {
                            $$('.item-bank-verify').empty();
                            $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-green-light text-color-green"><i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified</span>');
                            //show withdrwal
                            $$('.widthdrwal-block').removeClass('hidden');
                            $$('.maxLoanamount').html(formatter.format(userData.maxLoan));
                          }
                          else if(data.responseCode == 'E00000') {
                            app.toast.show({
                              text: 'This account number is invalid. Please check and try again.',
                              closeTimeout: 3000,
                            });
                            $$('.item-bank-verify').empty();
                            $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Invalid Bank Account</span>');
                            $$('.block-error-txt-bank').empty();
                            $$('.block-error-txt-bank').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">exclamationmark_circle</i>\
                            <span>'+data.remarks+'</span>');
                          }
                          else if(data.errorcode == 'name_not_matched') {
                            app.toast.show({
                              text: data.msg,
                              closeTimeout: 3000,
                            });
                            $$('.item-bank-verify').empty();
                            $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update Name</span>');
                            $$('.block-error-txt-bank').empty();
                            $$('.block-error-txt-bank').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">exclamationmark_circle</i>\
                            <span>'+data.msg+'</span>');
                          }
                          else {
                            app.toast.show({
                              text: 'Something went wrong, please try again',
                              closeTimeout: 3000,
                            });
                          }
                        },
                        function() {
                          $$('.button').removeClass('disabled');
                          app.dialog.close();
                          app.toast.show({
                            text: 'Something went wrong!',
                            closeTimeout: 3000,
                          });
                        }
                      );
                    }
                    var card_step = '<div class="step step-pending">\
                    <div>\
                      <div class="circle pulse"><i class="icon f7-icons text-color-red-deep fs-16">lock_fill</i></div>\
                    </div>\
                    <div>\
                      <div class="title">Unlock Finance Card</div>\
                      <div class="caption">Please unlock your '+data.cardName+' Card.</div>\
                    </div>\
                    </div>';
                    $$('.application_progress_st__txt').html('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i> Unlock Finance Card: Say hello to a new era of financial flexibility with the Finance Card. \
                    Seamlessly navigate through a world of loan options designed just for you. Your gateway to convenient and customized borrowing solutions is now within reach.');
                    $$('.application_progress_st__btn').html('Unlock Finance Card');
                    
                  }
                  //check kyc
                  if(userData.kycData[0].is_aadhaar == 1 && userData.kycData[0].is_pan == 1) {
                    var pulse_class_offers = "pulse";
                    var kyc_step = '<div class="step step-active">\
                    <div>\
                      <div class="circle"><i class="icon f7-icons text-color-green fs-20">checkmark_alt_circle_fill</i></div>\
                    </div>\
                    <div>\
                      <div class="title">KYC Verified</div>\
                      <div class="caption">Congratulations! Your Aadhaar and PAN verification has been successfully completed.</div>\
                    </div>\
                    </div>';
                    $$('.application_progress_st__txt').html('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i> We will obtain your Credit Report \
                    from credit bureaus to evaluate your eligibility for loan offers from our lenders. \
                    This procedure will determine your qualification for available loan options. \
                    If you meet the eligibility criteria for loan offers from our esteemed lenders, you will receive multiple offers. \
                    In the unlikely event that you do not receive any offers within a span of three months, kindly reach out to us for assistance.');
                    $$('.application_progress_st__btn').html('Currently Unavailable!');
                    $$('.application_progress_st__btn').attr('href', '#');
                    $$('.application_progress_st__btn').removeClass('bg-color-red-light');
                    $$('.application_progress_st__btn').removeClass('text-color-red');
                    $$('.application_progress_st__btn').addClass('bg-color-blue');
                    $$('.application_progress_st__btn').addClass('text-color-white btn-alt');
                  } else {
                    var kyc_step = '<div class="step step-pending">\
                    <div>\
                      <div class="circle '+pulse_class_kyc+'"><i class="icon f7-icons text-color-red-deep fs-20">exclamationmark_circle_fill</i></div>\
                    </div>\
                    <div>\
                      <div class="title">Complete KYC</div>\
                      <div class="caption">Ensure a seamless process by completing the Know Your Customer (KYC) verification.</div>\
                    </div>\
                    </div>';
                  }
                  $$('.block__loanDetails').removeClass('hidden');
                  $$('.block__bankDetails').removeClass('hidden');
                  $$('.block__feesDetails').removeClass('hidden');
                  $$('.block__moreDetails').removeClass('hidden');
                  //show progress
                  $$('.block-tracker').append('<div class="step step-active">\
                    <div>\
                      <div class="circle"><i class="icon f7-icons text-color-green fs-20">checkmark_alt_circle_fill</i></div>\
                    </div>\
                    <div>\
                      <div class="title">Application Submitted</div>\
                      <div class="caption">'+data.date_applied+'</div>\
                    </div>\
                  </div>\
                  <div class="step step-active">\
                      <div>\
                        <div class="circle"><i class="icon f7-icons text-color-green fs-20">checkmark_alt_circle_fill</i></div>\
                      </div>\
                      <div>\
                        <div class="title">Application Approved</div>\
                        <div class="caption">'+data.date_approved+'</div>\
                      </div>\
                  </div>\
                  '+card_step+'\
                  '+kyc_step+'\
                  <div class="step step-pending">\
                    <div>\
                      <div class="circle '+pulse_class_offers+'"><i class="icon f7-icons text-color-red-deep fs-20">exclamationmark_circle_fill</i></div>\
                    </div>\
                    <div>\
                      <div class="title">Loan Offers</div>\
                      <div class="caption">Once you activate your Finance Card and confirming your KYC, unlock a plethora of personalized loan offers from our extensive network of banks and NBFCs.</div>\
                    </div>\
                  </div>\
                  <div class="step step-pending">\
                    <div>\
                      <div class="circle"><i class="icon f7-icons text-color-red-deep fs-20">exclamationmark_circle_fill</i></div>\
                    </div>\
                    <div>\
                      <div class="title">Loan Approval</div>\
                      <div class="caption">After selecting your desired loan offer, undergo a swift approval process with our lending partners.</div>\
                    </div>\
                  </div>\
                  <div class="step step-pending">\
                    <div>\
                      <div class="circle"><i class="icon f7-icons text-color-red-deep fs-20">exclamationmark_circle_fill</i></div>\
                    </div>\
                    <div>\
                      <div class="title">Loan Disbursement</div>\
                      <div class="caption">Once approved, the loan amount will be disbursed directly to your registered bank account.</div>\
                    </div>\
                  </div>');
                }
              })
            } else {
              $$('.block__loanDetails').hide();
              $$('.card__applyLoanLinkx').attr('href','/form1/');
              $$('.card__applyLoanLinkx').removeClass('hidden');
              $$('.card__applyLoanLinkx').html('Submit application');
              $$('.card__applyLoanIMG').attr('src', 'assets/gif/apply.gif');
            }
          } else {
            $$('.block__loanDetails').hide();
            app.dialog.close();
          }
          if(data.isLoggedin == 'false' && data.status == 'blocked') {
            app.views.main.router.navigate({name: 'Home'});
          }
          if(data.isLoggedin == 'false') {
            app.views.main.router.navigate({name: 'Home'});
          }
        });
        // Create dynamic Sheet
        var dynamicSheet = app.sheet.create({
          content: '<div class="sheet-modal">\
                      <div class="toolbar">\
                        <div class="toolbar-inner">\
                          <div class="left"></div>\
                          <div class="right">\
                            <a class="link sheet-close">Done</a>\
                          </div>\
                        </div>\
                      </div>\
                      <div class="sheet-modal-inner">\
                        <div class="block">\
                          <p>Sheet created dynamically.</p>\
                          <p><a href="#" class="link sheet-close">Close me</a></p>\
                        </div>\
                      </div>\
                    </div>',
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        // Open dynamic sheet
        $$(document).on('.view__LoanDetails','click', function () {
          // Close inline sheet before
          app.sheet.close('.my-sheet');
          // Open dynamic sheet
          dynamicSheet.open();
        });
      }
    }
  },
  {
    path: '/creditScore/',
    component: creditscorePage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        
      }
    }
  },
  {
    path: '/notification/',
    component: notificationPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        
      }
    }
  },
  {
    path: '/kyc/',
    component: kycPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        //api status
        app.dialog.progress('Loading...');
        app.methods.getAPI(function(data) {
          app.dialog.close();
          data.forEach(val => {
            if(val.name == 'kyc' && val.value == '0') {
              $$('.kyc_service').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">KYC service is offline!</span>');
              $$('.item-aadhaar-verify').empty();
              $$('.item-pan-verify').empty();
              $$('.block-error-txt').empty();
              $$('.block-error-txt').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>Our Aadhaar verification system is currently undergoing maintenance. Kindly return at a later time. We apologize for any inconvenience caused.</span>');
              $$('.block-error-txt-pan').empty();
              $$('.block-error-txt-pan').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>Our PAN verification system is currently undergoing maintenance. Please revisit at a later time. We apologize for any inconvenience this may cause.</span>');
              //enable manual procedure
              $$('.block__kyc__manual').removeClass('hidden');
            } if(val.name == 'kyc' && val.value == '1') {
              $$('.kyc_service').append('<span class="badge badge-ptf bg-color-green-light text-color-green">KYC service is online!</span>');
            }
          });
        });
        app.methods.getUserData(function(data) {
          if(data.isLoggedin == 'true') {
            $$('.profile-aadhaar').html(data.aadhaar);
            $$('.profile-pan').html(data.pan);
            if(data.kycData[0].is_aadhaar == 1) {
              $$('.block-error-txt').empty();
              $$('.aadhaar_li__kyc').addClass('hidden');
              $$('.item-aadhaar-verify').empty();
              $$('.item-aadhaar-verify').append('<span class="badge badge-ptf bg-color-green-light text-color-green"><i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified</span>');
            }
            if(data.kycData[0].is_pan == 1) {
              $$('.block-error-txt-pan').empty();
              $$('.pan_li__kyc').addClass('hidden');
              $$('.item-pan-verify').empty();
              $$('.item-pan-verify').append('<span class="badge badge-ptf bg-color-green-light text-color-green"><i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified</span>');
            }
            if(data.kycData[0].is_fail_aadhaar == 'error_invalid_aadhaar' && data.kycData[0].is_aadhaar == 0) {
              $$('.item-aadhaar-verify').empty();
              $$('.item-aadhaar-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Invalid Aadhar</span>');
              $$('.block-error-txt').empty();
              $$('.block-error-txt').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>We kindly request you to get in touch with us to update your Aadhaar card information along with the necessary proof. Once the update is complete, please return at your convenience to finalize the KYC verification process.</span>');
            }
            if(data.kycData[0].is_fail_aadhaar == 'name_not_matched' && data.kycData[0].is_aadhaar == 0) {
              $$('.item-aadhaar-verify').empty();
              $$('.item-aadhaar-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update name</span>');
              $$('.block-error-txt').empty();
              $$('.block-error-txt').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>Kindly update your name in the profile settings to match your Aadhaar card details. Once updated, please return to complete your KYC process.</span>');
            }
            if(data.kycData[0].is_fail_aadhaar == 'pin_not_matched' && data.kycData[0].is_aadhaar == 0) {
              $$('.item-aadhaar-verify').empty();
              $$('.item-aadhaar-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update address</span>');
              $$('.block-error-txt').empty();
              $$('.block-error-txt').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>We kindly request you to get in touch with us for updating your registered address, along with the necessary address proof. Once the update is complete, please return to complete your KYC verification process.</span>');
            }
            if(data.kycData[0].is_fail_pan == 'name_not_matched' && data.kycData[0].is_pan == 0) {
              $$('.item-pan-verify').empty();
              $$('.item-pan-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update name</span>');
              $$('.block-error-txt-pan').empty();
              $$('.block-error-txt-pan').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>Kindly update your name in the profile settings to match your PAN card details. Once updated, please return to complete your KYC process.</span>');
            }
            if(data.kycData[0].is_fail_pan == 'error_invalid_pan' && data.kycData[0].is_pan == 0) {
              $$('.item-pan-verify').empty();
              $$('.item-pan-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Invalid PAN</span>');
              $$('.block-error-txt-pan').empty();
              $$('.block-error-txt-pan').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>We kindly request you to contact us for updating your PAN card information along with the required proof. After the update, please return at your convenience to complete the KYC verification process.</span>');
            }
            if(data.kycData[0].aadhaar_img != null && data.kycData[0].is_aadhaar == null) {
              $$('.kyc__aadhaarManual_txt').removeClass('hidden');
              $$('.kyc__aadhaarManual_txt').html('Your Aadhaar card verification is under review. Please check back later.');
            }
            if(data.kycData[0].pan_img != null && data.kycData[0].is_pan == null) {
              $$('.kyc__panManual_txt').removeClass('hidden');
              $$('.kyc__panManual_txt').html('Your PAN card verification is under review. Please check back later.');
            }
            if(data.kycData[0].is_kyc == true) {
              $$('.block__kyc__manual').addClass('hidden');
              $$('.kyc_service').empty();
              $$('.item-text-aadhaar').addClass('hidden');
              $$('.item-text-pan').addClass('hidden');
            }
          }
          if(data.isLoggedin == 'false' && data.status == 'blocked') {
            app.views.main.router.navigate({name: 'Home'});
          }
          if(data.isLoggedin == 'false') {
            app.views.main.router.navigate({name: 'Home'});
          }
        });
        //verify aadhaar card
        $$('.aadhaarVerify').on('click',function(){
          verifyAadhaar(localStorage.getItem('authToken'), false);
        });
        //aadhaar verification sending otp
        function verifyAadhaar(authToken, resend) {
          var data = 'token='+authToken+'&aadhaarVerify=true';
          app.methods.request(
            baseURL+'/kycAPI.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Aadhaar Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.setItem('ref_id', data.ref_id);
                if(resend == false) {
                  app.popup.open('.sheet-OTP--aadhaar');
                  resend_otp_countdown();
                } else {
                  app.toast.show({
                    text: data.message,
                    closeTimeout: 3000,
                  });
                  resend_otp_countdown();
                }
              }
              else if(data.status == 'error' && data.error_code == 'error_invalid_aadhaar') {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
                $$('.item-aadhaar-verify').empty();
                $$('.item-aadhaar-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Invalid Aadhar</span>');
                $$('.block-error-txt').empty();
                $$('.block-error-txt').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">exclamationmark_circle</i>\
                <span>'+data.message+'</span>');
              }
              else {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //countdown to resend otp
        function resend_otp_countdown() {
          var countDownTo = new Date().setSeconds(new Date().getSeconds() + 30);
          $('#countdown').countdown(countDownTo, function(event) {
              $(this).html(event.strftime('%M:%S'));
          }).on('finish.countdown', function() {
              $$('.resendOTPaadhaar').removeClass('disabled');
              $$('#countdown').html('');
          });
        }
        //resend OTP
        $$('.resendOTPaadhaar').on('click',function(){
          $$('.resendOTPaadhaar').addClass('disabled');
          verifyAadhaar(localStorage.getItem('authToken'), true);
        });
        //verify otp for aadhaar
        $$('.aadhaarOTPverify').on('click',function(){
          if (!$$('#adh-otp')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyAadhaarOTP();
          }
        });
        function verifyAadhaarOTP() {
          var otp = $('#adh-otp').val();
          var data = 'otp='+otp+'&ref_id='+localStorage.getItem('ref_id')+'&aadhaarVerifyOTP=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/kycAPI.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying OTP...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.removeItem('ref_id');
                app.popup.close('.sheet-OTP--aadhaar');
                $$('.item-aadhaar-verify').empty();
                $$('.item-aadhaar-verify').append('<span class="badge badge-ptf bg-color-green-light text-color-green"><i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified</span>');
              }
              else if(data.errorcode == 'name_not_matched') {
                localStorage.removeItem('decentroTxnId');
                app.popup.close('.sheet-OTP--aadhaar');
                $$('.item-aadhaar-verify').empty();
                $$('.item-aadhaar-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update Name</span>');
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
                $$('.block-error-txt').empty();
                $$('.block-error-txt').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">exclamationmark_circle</i>\
                <span>'+data.msg+'</span>');
              }
              else if(data.errorcode == 'pin_not_matched') {
                localStorage.removeItem('decentroTxnId');
                app.popup.close('.sheet-OTP--aadhaar');
                $$('.item-aadhaar-verify').empty();
                $$('.item-aadhaar-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update Address</span>');
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
                $$('.block-error-txt').empty();
                $$('.block-error-txt').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">exclamationmark_circle</i>\
                <span>'+data.msg+'</span>');
              }
              else {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify pan card
        $$('.panVerify').on('click',function(){
          verifyPAN();
        });
        function verifyPAN() {
          var data = 'panVerify=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/kycAPI.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying PAN Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                $$('.item-pan-verify').empty();
                $$('.item-pan-verify').append('<span class="badge badge-ptf bg-color-green-light text-color-green"><i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified</span>');
              }
              else if(data.responseKey == 'error_invalid_pan') {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
                $$('.item-pan-verify').empty();
                $$('.item-pan-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Invalid PAN</span>');
                $$('.block-error-txt-pan').empty();
                $$('.block-error-txt-pan').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">exclamationmark_circle</i>\
                <span>'+data.error.message+'</span>');
              }
              else if(data.errorcode == 'name_not_matched') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
                $$('.item-pan-verify').empty();
                $$('.item-pan-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update Name</span>');
                $$('.block-error-txt-pan').empty();
                $$('.block-error-txt-pan').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">exclamationmark_circle</i>\
                <span>'+data.msg+'</span>');
              }
              else {
                app.toast.show({
                  text: data.message,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //upload aadhaar
        $$('#aadhaarImg').on('change', function() {
          var aadhaarImg = $$('#aadhaarImg').prop('files')[0];
          var formData = new FormData();
          formData.append('aadhaarUpload', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('aadhaarImg', aadhaarImg);
          formData.append('uuid', app.methods.getDeviceUUID());
          var url = baseURL+'investment.php';
          var file = this.files[0];
          var fileType = file['type'];
          //image format jpeg, jpg, png small and caps
          var validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/JPEG", "image/PNG", "image/JPG"];
          
          if(!$$('#aadhaarImg')[0].checkValidity()) {
            app.toast.show({
              text: 'Please upload your aadhaar image!',
              closeTimeout: 3000,
            });
          }
          else if ($.inArray(fileType, validImageTypes) < 0) {
            app.toast.show({
              text: 'This is not an Image!',
              closeTimeout: 3000,
            });
          }
          else {
            uploadKYCImg(url, formData)
          }
        });
        //upload pan
        $$('#panImg').on('change', function() {
          var panImg = $$('#panImg').prop('files')[0];
          var formData = new FormData();
          formData.append('panUpload', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('panImg', panImg);
          formData.append('uuid', app.methods.getDeviceUUID());
          var url = baseURL+'investment.php';
          var file = this.files[0];
          var fileType = file['type'];
          //image format jpeg, jpg, png small and caps
          var validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/JPEG", "image/PNG", "image/JPG"];
          
          if(!$$('#panImg')[0].checkValidity()) {
            app.toast.show({
              text: 'Please upload your PAN image!',
              closeTimeout: 3000,
            });
          }
          else if ($.inArray(fileType, validImageTypes) < 0) {
            app.toast.show({
              text: 'This is not an Image!',
              closeTimeout: 3000,
            });
          }
          else {
            uploadKYCImg(url, formData)
          }
        });
        //function to upload kyc images
        function uploadKYCImg(url, formData) {
          app.methods.post(
            url,
            formData,
            'json',
            function() {
              app.dialog.progress('Uploading...');
            },
            function(data) {
              app.dialog.close();
              if(data.status == 'success') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
                var view = app.views.current;
                view.router.back('/investment-dashboard/',{force:true});
              }
              else {
                app.toast.show({
                  text: data.status,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              app.dialog.close();
              app.toast.show({
              text: 'Something went wrong!',
              closeTimeout: 3000,
            });
            }
          );
        }
      }
    }
  },
  {
    path: '/paymentSuccess/:type/:txnID',
    component: paymentSuccessPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var tnxID = page.route.params.txnID;
        var type = page.route.params.type;
        //check payment status
        if(type == 'investment') {
          var formData = {checkPayment:true,txnID:tnxID,token:localStorage.getItem('authToken'),uuid:localStorage.getItem('uuid'),type:'investment'};
        } else if(type == 'renewal') {
          var formData = {checkPayment:true,txnID:tnxID,token:localStorage.getItem('authToken'),uuid:localStorage.getItem('uuid'),type:'renewal'};
        } else {
          var formData = {checkPayment:true,txnID:tnxID,token:localStorage.getItem('authToken'),uuid:localStorage.getItem('uuid'),type:'card'};
        }
        //run check payment in 5sec interval
        checkPayment(formData, type);
        function checkPayment(formData, type) {
          app.methods.request(
            'https://phonetouchfinance.in/api/v1/payment/',
            formData,
            'json',
            function() {
              $$('.block-loader').removeClass('hidden');
            },
            function(data) {
              $$('.block-loader').addClass('hidden');
              if(data.code == 'PAYMENT_SUCCESS') {
                $$('.block-paymentST').empty();
                $$('.block-paymentST').append('<div class="text-align-center status-success">\
                  <div class="check-container">\
                      <div class="check-background">\
                          <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">\
                              <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />\
                          </svg>\
                      </div>\
                      <div class="check-shadow"></div>\
                  </div>\
                  <h3 class="text-color-green fs-24 fw-600 animate__animated animate__heartBeat">Payment Receieved</h3>\
                  <p class="text-color-blue-light fs-14 fw-500 m-0">Transaction ID: '+data.data.merchantTransactionId+'</p>\
                  <p class="text-color-blue-light fs-12 fw-500 text-align-justify">Thank you for your payment. \
                  Your transaction is successful and your payment has been received. \
                </div>');
                if(type == 'investment') {
                  setTimeout(function() {
                    app.views.main.router.navigate('/investment-dashboard/',{force:true});
                  }
                  , 3000);
                } else if(type == 'renewal') {
                  setTimeout(function() {
                    app.views.main.router.navigate('/investment-dashboard/',{force:true});
                  }
                  , 3000);
                } else {
                  setTimeout(function() {
                    app.views.main.router.navigate({name: 'Home'});
                  }
                  , 3000);
                }
              }
              else if(data.code == 'PAYMENT_PENDING') {
                $$('.block-paymentST').empty();
                $$('.block-paymentST').append('<div class="text-align-center status-error">\
                <div class="check-container-error">\
                    <div class="check-background-error">\
                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">\
                            <path stroke="white" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" d="M16 16 36 36 M36 16 16 36"/>\
                        </svg>\
                    </div>\
                    <div class="check-shadow-error"></div>\
                </div>\
                <h3 class="text-color-red-deep fs-24 fw-600 animate__animated animate__heartBeat">Payment Pending</h3>\
                <p class="text-color-blue-light fs-14 fw-500 m-0">Transaction ID: '+data.data.merchantTransactionId+'</p>\
                <p class="text-color-blue-light fs-14 fw-500 m-0">Reason: '+data.data.responseCodeDescription+'</p>\
                <p class="text-color-blue-light fs-12 fw-500 text-align-justify">Payment Pending: Your payment is currently processing.\
                 We\'ll notify you once it\'s complete. If you have any concerns, please contact our support team. Thank you for your patience.</p>\
              </div>');
              setTimeout(function() {
                app.views.main.router.navigate({name: 'Home'});
              }
              , 3000);
              }
              else if(data.code == 'PAYMENT_ERROR') {
                $$('.block-paymentST').empty();
                $$('.block-paymentST').append('<div class="text-align-center status-error">\
                <div class="check-container-error">\
                    <div class="check-background-error">\
                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">\
                            <path stroke="white" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" d="M16 16 36 36 M36 16 16 36"/>\
                        </svg>\
                    </div>\
                    <div class="check-shadow-error"></div>\
                </div>\
                <h3 class="text-color-red-deep fs-24 fw-600 animate__animated animate__heartBeat">Payment Failed</h3>\
                <p class="text-color-blue-light fs-14 fw-500 m-0">Transaction ID: '+data.data.merchantTransactionId+'</p>\
                <p class="text-color-blue-light fs-14 fw-500 m-0">Reason: '+data.data.responseCodeDescription+'</p>\
                <p class="text-color-blue-light fs-12 fw-500 text-align-justify">Payment Failed: We regret to inform you that your recent payment attempt was unsuccessful.\
                 Please check your payment details and try again. If you continue to experience issues, contact our support team for assistance. Thank you.</p>\
                </div>');
                setTimeout(function() {
                  app.views.main.router.navigate({name: 'Home'});
                }
                , 3000);
              }
              else if(data.code == 'EXPIRED') {
                $$('.block-paymentST').empty();
                $$('.block-paymentST').append('<div class="text-align-center status-error">\
                <div class="check-container-error">\
                    <div class="check-background-error">\
                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">\
                            <path stroke="white" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" d="M16 16 36 36 M36 16 16 36"/>\
                        </svg>\
                    </div>\
                    <div class="check-shadow-error"></div>\
                </div>\
                <h3 class="text-color-red-deep fs-24 fw-600 animate__animated animate__heartBeat">Payment Expired</h3>\
                <p class="text-color-blue-light fs-12 fw-500 text-align-justify">Payment Expired: This payment request has expired. Please try again or contact our support team for assistance. Thank you.</p>\
                </div>');
                setTimeout(function() {
                  app.views.main.router.navigate({name: 'Home'});
                }
                , 3000);
              } else {
                $$('.block-paymentST').empty();
                $$('.block-paymentST').append('<div class="text-align-center status-error">\
                <div class="check-container-error">\
                    <div class="check-background-error">\
                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">\
                            <path stroke="white" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" d="M16 16 36 36 M36 16 16 36"/>\
                        </svg>\
                    </div>\
                    <div class="check-shadow-error"></div>\
                </div>\
                <h3 class="text-color-red-deep fs-24 fw-600 animate__animated animate__heartBeat">Payment Failed!</h3>\
                <p class="text-color-blue-light fs-12 fw-500 text-align-justify">Payment Failed: We regret to inform you that your recent payment attempt was unsuccessful. Please check your payment details and try again. If you continue to experience issues, contact our support team for assistance. Thank you.</p>\
                </div>');
                setTimeout(function() {
                  app.views.main.router.navigate({name: 'Home'});
                }
                , 3000);
              }
            },
            function() {
              setTimeout(() => {
                checkPayment(formData, type);
              }, 3000);
            }
          );
        }
      }
    }
  },
  {
    path: '/investment/',
    component: investmentPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //amount masking
        $$('input.amount').keyup(function(event) {
          var val = $(this).val().replace(/[₹ ,]/g,'');
          $$('input#amount').val(val);
          // skip for arrow keys
          if(event.which >= 37 && event.which <= 40){
           event.preventDefault();
          }
          $(this).val(function(index, value) {
              value = value.replace(/,/g,'');
              if(value.indexOf('₹') > -1) {
                return app.methods.addComma(value);
              }
              else {
                return '₹ '+app.methods.addComma(value);
              }
          });
        });
        $$('.btn-invest').off('click');
        $$('.btn-invest').on('click',function(){
          //check validity of amount field
          if (!$$('.amount')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            var amount = $$('#amount').val();
            //check if amount is minimum 10000 and maximum 5000000
            if(amount < 5000 || amount > 5000000) {
              app.toast.show({
                text: 'Please enter an amount between ₹5,000 and ₹5,000,000',
                closeTimeout: 3000,
              });
            }
            //check if amount is not in decimal
            else if(amount % 1 != 0) {
              app.toast.show({
                text: 'Please enter a valid amount!',
                closeTimeout: 3000,
              });
            }
            else {
              localStorage.setItem('maxinvest', amount);
              app.views.main.router.navigate('/invest-form/');
            }
          }
        });
      }
    }
  },
  {
    path: '/invest-form/',
    component: investmentformPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.dialog.progress('Loading...');
        app.methods.getUserData(function(data) {
          app.dialog.close();
          if(data.isLoggedin == 'true') {
            if(data.kycData[0].is_aadhaar == 1 && data.kycData[0].aadhaar_temp != null) {
              $$('.kyc__ver1').removeClass('hidden');
              $$('#aadhaar').attr('readonly', true);
              $$('#aadhaar').val(data.kycData[0].aadhaar_temp);
              $$('.item-input-aadhaar').addClass('item-input-focused item-input-with-value');
            }
            if(data.kycData[0].is_pan == 1 && data.kycData[0].pan_temp != null) {
              $$('.kyc__ver2').removeClass('hidden');
              $$('#panNum').attr('readonly', true);
              $$('#panNum').val(data.kycData[0].pan_temp);
              $$('.item-input-pan').addClass('item-input-focused item-input-with-value');
            }
            if(data.kycData[0].is_bank == 1 && data.kycData[0].bank_temp != null) {
              var bankDetails = JSON.parse(data.kycData[0].bank_temp);
              $$('.kyc__ver3').removeClass('hidden');
              $$('#bankacc').attr('readonly', true);
              $$('#bankacc').val(bankDetails.bankAC);
              $$('#ifsc').attr('readonly', true);
              $$('#ifsc').val(bankDetails.bankIFSC);
              $$('#bankname').attr('readonly', true);
              $$('#bankname').val(bankDetails.bankName);
              $$('.bankBranchName').removeClass('hidden');
              $$('.bankBranchName').html(bankDetails.bankBranch);
              $$('#branch').val(bankDetails.bankBranch);
              $$('.item-input-ac, .item-input-ifsc, .item-input-bank').addClass('item-input-focused item-input-with-value');
            }
          }
          if(data.isLoggedin == 'false' && data.status == 'blocked') {
            app.views.main.router.navigate({name: 'Home'});
          }
          if(data.isLoggedin == 'false') {
            app.views.main.router.navigate({name: 'Home'});
          }
        });
        //show kyc detials on sheet modal
        $$('.showKycInfo').on('click', function() {
          //open a sheet modal for renewal
          showKycInfo.open();
        });
        //create a sheet modal for kyc details
        var showKycInfo = app.sheet.create({
          content: '<div class="sheet-modal showKycSheet" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
            <div class="sheet-modal-inner">\
              <div class="block-container">\
              <h4 class="text-color-blue-light fs-16 fw-600 margin-left margin-right">KYC Guidelines</h4>\
              <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet">\
                <i class="icon f7-icons text-color-gray" style="font-size: 30px;">xmark_circle_fill</i>\
              </a>\
              <p class="text-color-blue-light fs-12 margin-left margin-right info-ptf" style="padding-top: 0">\
              <br/>1. Ensure your Aadhaar name matches with your registered name.\
              <br/>2. Please confirm that pincode on your Aadhaar matches with your registered pincode.\
              <br/>3. Please double-check that your registered name matches your PAN card.\
              <br/>4. Any inconsistencies could result in a failed KYC verification.\
              </p>\
            </div>\
          </div>',
          closeByBackdropClick: true,
          // Events
          on: {
            open: function (sheet) {
            },
            opened: function (sheet) {
            },
          }
        });
        //show bank detials on sheet modal
        $$('.showBankInfo').on('click', function() {
          //open a sheet modal for renewal
          showBankInfo.open();
        });
        //create a sheet modal for kyc details
        var showBankInfo = app.sheet.create({
          content: '<div class="sheet-modal showBankSheet" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
            <div class="sheet-modal-inner">\
              <div class="block-container">\
              <h4 class="text-color-blue-light fs-16 fw-600 margin-left margin-right">Bank Guidelines</h4>\
              <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet">\
                <i class="icon f7-icons text-color-gray" style="font-size: 30px;">xmark_circle_fill</i>\
              </a>\
              <p class="text-color-blue-light fs-12 margin-left margin-right info-ptf" style="padding-top: 0">\
              <br/>1. You should be the primary bank account holder.\
              <br/>2. We will conduct a bank verification at a later stage.\
              </p>\
            </div>\
          </div>',
          closeByBackdropClick: true,
          // Events
          on: {
            open: function (sheet) {
            },
            opened: function (sheet) {
            },
          }
        });
        //get ifsc
        $$('#ifsc').on('keyup', function() {
          $$('.bankBranchName').html('');
          $$('#branch').val('');
          if($$('#ifsc').val().length == 11) {
            $.getJSON('https://ifsc.razorpay.com/'+$$('#ifsc').val(), function(data) {
              $$('.bankBranchName').removeClass('hidden');
              $$('.bankBranchName').html(data.BRANCH);
              $$('#branch').val(data.BRANCH);
              $$('#bankname').val(data.BANK);
              $$('.item-input-bank').addClass('item-input-focused');
              $$('.item-input-bank').addClass('item-input-with-value');
            });
          }
        })
        //input validation
        $$('.invest-submit').on('click', function() {
          var amount = localStorage.getItem('maxinvest');
          var pan = $$('#panNum').val();
          var bankAcc = $$('#bankacc').val();
          var IFSC = $$('#ifsc').val();
          var aadhaar = $$('#aadhaar').val();
          var bankname = $$('#bankname').val();
          var branch = $$('#branch').val();
          var formData = new FormData();
          
          formData.append('submit-invest', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('amount', amount);
          formData.append('aadhaar', aadhaar);
          formData.append('pan', pan);
          formData.append('bankacc', bankAcc);
          formData.append('bankname', bankname);
          formData.append('ifsc', IFSC);
          formData.append('branch', branch);
          formData.append('uuid', app.methods.getDeviceUUID());
          var error = 0;
          for (var value of formData.values()) {
            if(value == '' || value == 'undefined') {
              error += 1;
            }
          }
          if(error == 0) {
            app.methods.post(
              baseURL+'investment.php',
              formData,
              'json',
              function() {
                $$('.button').addClass('disabled');
                app.dialog.progress('Processing...');
              },
              function(data) {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.dialog.close();
                if(data.status == 'success') {
                  app.popup.open('.sheet-invest--applied');
                  $$('.sheet-loan--applied__title').html('Your Investment of '+formatter.format(data.amount)+' has been submitted.');
                  $$('.sheet-loan--applied__text').html('Your request is under process. Contact us for any queries. Your reference number is: '+data.ref);
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else if(data.errorcode == 'applied') {
                  app.popup.open('.sheet-invest--applied');
                  $$('.sheet-loan--applied__title').html('Your last requested investment is still pending!');
                  $$('.sheet-loan--applied__text').html('You can only apply for a new investment after your last request is processed. Your reference number is: '+data.ref);
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else {
                  app.toast.show({
                    text: 'Something went wrong, please try again',
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
              }
            );
          }
          else {
            for (var pair of formData.entries()) {
              console.log(pair[0]+ ', ' + pair[1]); 
            }
            $$('#invest-form')[0].checkValidity();
            $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'Please complete all the required fields!',
                closeTimeout: 3000,
              });
          }
        });
      }
    }
  },
  {
    path: '/investment-dashboard/',
    component: investmentdashboardPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if invested or not
        app.dialog.progress('Loading...');
        app.methods.getUserDataInvest(
          function(data) {
            app.dialog.close();
            if(data.isInvested == true) {
              if(data.isHoliday == true) {
                $$('.block__holiday').removeClass('hidden');
              }
              $$('.card__listInvest').empty();
              var list_invest = '';
              $.each(data.investmentData, function(key, val) {
                if(val.status == '0') {
                  var badge = '<span class="investSTbadge badge badge-ptf bg-color-red-deep text-color-white btn-shine-cm badge-ptf-comingsoon ml-0">Inactive</span>';
                } else if(val.status == '1') {
                  var badge = '<span class="investSTbadge badge badge-ptf bg-color-green-light text-color-green btn-shine-cm badge-ptf-comingsoon ml-0">Active</span>';
                } else {
                  var badge = '<span class="investSTbadge badge badge-ptf bg-color-blue text-color-white btn-shine-cm badge-ptf-comingsoon ml-0">Closed</span>';
                }
                list_invest += '<div class="card card-main margin-top col-50 card-bs-shadow" style="width:45%;">\
                <div class="card-content">\
                  <div class="list media-list credit-list">\
                    <ul>\
                      <li>\
                          <a href="/investment-details/'+val.refID+'" class="item-link item-content">\
                              <div class="item-media"><img src="assets/icons/invest-success.png" width="45"/></div>\
                              <div class="item-inner">\
                                  <div class="item-title text-color-blue-light fs-16 fw-600">'+INR.format(val.amount)+'</div>\
                                  <div class="item-text fs-12">Investment</div>\
                                  <div class="investSTbadge">\
                                    '+badge+'\
                                  </div>\
                              </div>\
                          </a>\
                      </li>\
                      </ul>\
                  </div>\
                </div>\
              </div>';
              });
              $$('.card__listInvest').append(list_invest);
            } else {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if kyc is complete or not
        app.methods.getUserData(function(data) {
          if(data.kycData[0].is_kyc == true) {
            $$('.is__kycVerify').removeClass('bg-color-red-deep-light');
            $$('.is__kycVerify').addClass('bg-color-green-light text-color-green');
            $$('.is__kycVerify').html('<i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified');
          }
          //bank
          if(data.kycData[0].is_bank == '1') {
            $$('.is__bankVerify').removeClass('bg-color-red-deep-light');
            $$('.is__bankVerify').addClass('bg-color-green-light text-color-green');
            $$('.is__bankVerify').html('<i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified');
          }
        });
        //import investment from other accounts
        $$('.item-invest-import').on('click', function() {
          //show import investment sheet
          importInvestSheet.open();
        });
        //create a sheet modal for import investment
        var importInvestSheet = app.sheet.create({
          content: '<div class="sheet-modal importInvest--Sheet" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
            <div class="sheet-modal-inner">\
              <div class="block">\
              <h4 class="text-color-blue-light fs-16 fw-600 margin-left margin-right">Do you have an investment reference number?</h4>\
              <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet--payout">\
                <i class="icon f7-icons text-color-gray" style="font-size: 30px;">xmark_circle_fill</i>\
              </a>\
              <div class="text-center">\
                <img src="assets/icons/import.svg" style="height: 250px;">\
              </div>\
              <p class="text-color-blue-light fs-12 margin-left margin-right info-ptf" style="padding-top: 0">\
              <br/>You can import your investment from other accounts using the investment reference number. \
              You will need to verify your investment with an OTP, which will be sent to your other registered mobile number. \
              </p>\
              <div class="list no-hairlines-md">\
                <ul>\
                  <li class="item-content item-input">\
                    <div class="item-inner">\
                      <div class="item-input-wrap">\
                        <input type="text" class="investRefID" name="investRefID" placeholder="Investment Reference Number" validate required data-error-message="Please enter your investment reference number" />\
                      </div>\
                    </div>\
                  </li>\
                </ul>\
              </div>\
              <a href="#" class="margin-top button button-fill button-large button-fill bg-color-red text-color-white btn-alt btn-inportInvest">Import</a>\
            </div>\
          </div>',
          closeByBackdropClick: true,
          // Events
          on: {
            open: function (sheet) {
              //click on import investment
              $$('.btn-inportInvest').on('click', function() {
                var refID = $$('.investRefID').val();
                if (!$$('.investRefID')[0].checkValidity()) {
                  console.log('Check Validity!');
                } else {
                  var formData = new FormData();
                  formData.append('importInvestRequest', true);
                  formData.append('refID', refID);
                  formData.append('token', localStorage.getItem('authToken'));
                  formData.append('uuid', app.methods.getDeviceUUID());
                  app.methods.post(
                    baseURL+'investment.php',
                    formData,
                    'json',
                    function() {
                      app.dialog.progress('Validating...');
                    },
                    function(data) {
                      app.dialog.close();
                      if(data.status == 'success') {
                        //open popup for otp authentication
                        app.popup.open('.sheet-otp--import');
                        app.sheet.close('.importInvest--Sheet');
                        var phone = data.phone;
                        $$('.sheet-OTP--verification--phonenumber').html(data.phone);
                        $$('.sheet-OTP--verification--ref').html(refID);
                        $$('#verificationID').val(data.verificationID);
                      }
                      else {
                        app.toast.show({
                          text: data.msg,
                          closeTimeout: 3000,
                        });
                      }
                    },
                    function() {
                      app.dialog.close();
                      app.toast.show({
                        text: 'Something went wrong!',
                        closeTimeout: 3000,
                      });
                    }
                  );
                }
              });
            },
            opened: function (sheet) {
            },
          }
        });
        //submit OTP for import investment
        $$('.btn-importInvestOTP').on('click', function() {
          if(!$$('#invest-import-otp')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            var formData = new FormData();
            formData.append('importInvestOTP', true);
            formData.append('otp', $$('#invest-import-otp').val());
            formData.append('verificationID', $$('#verificationID').val());
            formData.append('refID', $$('.sheet-OTP--verification--ref').html());
            formData.append('token', localStorage.getItem('authToken'));
            formData.append('uuid', app.methods.getDeviceUUID());
            app.methods.post(
              baseURL+'investment.php',
              formData,
              'json',
              function() {
                app.dialog.progress('Processing your request...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  app.popup.close('.sheet-otp--import');
                  app.toast.show({
                    text: data.msg,
                    closeTimeout: 3000,
                  });
                  var view = app.views.current;
                  view.router.navigate('/investment-dashboard/',{reloadCurrent: true,ignoreCache:true});
                }
                else {
                  app.toast.show({
                    text: data.msg,
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                app.dialog.close();
                app.toast.show({
                  text: 'Something went wrong!',
                  closeTimeout: 3000,
                });
              }
            );
          }
        });
      }
    }
  },
  {
    path: '/investment-details/:refID',
    component: investmentdetailsPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var refID = page.route.params.refID;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if invested or not
        app.dialog.progress('Loading...');
        app.methods.getUserDataInvest(
          function(data) {
            app.dialog.close();
            if(data.isInvested == true) {
              const investmentData = data.investmentData.find(element => element.refID === refID);
              //check if investment is present is the array
              if(investmentData) {
                $$('.item-link-payment-history').attr('href', '/payment-history/'+investmentData.refID+'/'+'all');
                if(investmentData.lastReturn == null) {
                  $$('.return-today').html('₹0.00');
                } else {
                  $$('.return-today').html(formatter.format(investmentData.lastReturn));
                  $$('.return-today-date').html(investmentData.lastReturnDate);
                }
                $$('.invest-amount').html(INR.format(investmentData.amount));
                $$('.return-amount').html(INR.format(investmentData.returns));
                if(investmentData.payout_data.totalPayout == null) {
                  $$('.total-payout').html('₹0.00');
                } else {
                  $$('.total-payout').html(INR.format(investmentData.payout_data.totalPayout));
                }
                if(investmentData.payout_data.lastPayout == null && investmentData.payout_data.lastPayoutDate == null) {
                  $$('.last-payout').html('₹0.00');
                  $$('.last-payout-date').html('No payouts');
                } else {
                  $$('.last-payout').html(formatter.format(investmentData.payout_data.lastPayout));
                  $$('.last-payout-date').html(investmentData.payout_data.lastPayoutDate); 
                }
                //total withdraw,last withdraw, last date
                if(investmentData.withdraw_data.totalWithdraw == null) {
                  $$('.total-withdraw').html('₹0.00');
                } else {
                  $$('.total-withdraw').html(INR.format(investmentData.withdraw_data.totalWithdraw));
                }
                if(investmentData.withdraw_data.lastWithdraw == null && investmentData.withdraw_data.lastWithdrawDate == null) {
                  $$('.last-withdraw').html('₹0.00');
                  $$('.last-withdraw-date').html('No withdrawals');
                } else {
                  $$('.last-withdraw').html(formatter.format(investmentData.withdraw_data.lastWithdraw));
                  $$('.last-withdraw-date').html(investmentData.withdraw_data.lastWithdrawDate);
                }
                if(investmentData.isRenew == 0 && investmentData.renewalDate == null) {
                  $$('.renwal__days').html('0 days left');
                  $$('.renwal__text').html('No renewal available');
                }
                if(investmentData.isRenew == 1 && investmentData.renewalDate != null) {
                  $$('.renwal__days').html(investmentData.renewalDaysLeft+' days left');
                  $$('.renwal__text').html('Next renewal on '+investmentData.renewalDate);
                  $$('.item-link-renewal').attr('href', '/renewal-details/'+investmentData.refID);
                }
                //if renewal is expired
                if(investmentData.isRenew == 0 && investmentData.renewalDate != null) {
                  $$('.renwal__days').html('0 days left');
                  $$('.renwal__text').html('Renewal expired on '+investmentData.renewalDate);
                  $$('.item-link-renewal').attr('href', '/renewal-details/'+investmentData.refID);
                  $$('.renewal__status--txt').removeClass('hidden');
                  $$('.renewal__status--txt').html('Expired');
                }
                if(investmentData.isRenew == 1 && investmentData.renewalDate != null && investmentData.renewalDaysLeft == 0) {
                  $$('.renwal__days').html('0 days left');
                  $$('.renwal__text').html('Renewal expiring today');
                  $$('.item-link-renewal').attr('href', '/renewal-details/'+investmentData.refID);
                }
                if(investmentData.payment_data.isPaidForInvest == true) {
                  $$('.toolbar__payment').addClass('hidden');
                } else {
                  $$('.investPyamentBtn').attr('href', '/payment/investment/'+investmentData.refID);
                }
                if(investmentData.status == '2') {
                  //canceled
                  $$('.investSTbadge').empty();
                  $$('.investSTbadge').append('<span class="badge badge-ptf bg-color-blue text-color-white btn-shine-cm">Closed</span>');
                  $$('.invest__requestCloser').addClass('disabled');
                  $$('.investClosureTxt').html('Investment closed on '+investmentData.closeDate);
                  $$('.invest__certificateLink').addClass('disabled');
                }
                //if staus is active
                if(investmentData.status == '1') {
                  $$('.investSTbadge').empty();
                  $$('.investSTbadge').append('<span class="badge badge-ptf bg-color-green-light text-color-green btn-shine-cm">Active</span>');
                  if(investmentData.returnWallet > 0 || investmentData.withdraw_bal > 0) {
                    $$('.btn-invest__withdraw').removeClass('disabled');
                    $$('.btn-invest__withdraw').attr('href', '/wallet/'+refID);
                    var total_avlBal = +investmentData.withdraw_bal + +investmentData.returnWallet;
                    $$('.avl-bal').html(formatter.format(total_avlBal));
                  }
                  $$('.invest__certificateLink').attr('href', '/invest-certificate/'+investmentData.refID);
                  //when preclosure is 0
                  if(investmentData.preclosureDaysLeft == 0) {
                    $$('.invest__requestCloser').addClass('disabled');
                    $$('.investClosureTxt').html('Investment will be closed automatically on '+investmentData.closeDate);
                  } else {
                    $$('.daysLeft__investClose').html(investmentData.preclosureDaysLeft);
                  }
                  if(investmentData.isClosure == '1') {
                    $$('.invest__requestCloser').addClass('disabled');
                    $$('.investClosureTxt').html('Your request is under process. Contact us for any queries.');
                  }
                  $$('.item-link-txn').attr('href', '/transactions/'+investmentData.refID+'/all');
                  $$('.item-link-txnReturns').attr('href', '/transactions/'+investmentData.refID+'/returns');
                  $$('.item-link-txnWithdraw').attr('href', '/transactions/'+investmentData.refID+'/withdraw');
                  $$('.item-link-txnPayouts').attr('href', '/transactions/'+investmentData.refID+'/payout');
                } else {
                  $$('.invest__requestCloser').addClass('disabled');
                  $$('.investClosureTxt').html('Your investment is not eligible for preclosure.');
                }
              } else {
                app.views.main.router.navigate({name: 'Home'});
              }
            } else {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //open investment details modal
        $$('.item-link-investDetails').on('click', function() {
          //open a sheet modal for investment details
          investDetailsSheet.open();
        });
        //create a sheet modal for investment details
        var investDetailsSheet = app.sheet.create({
          content: '<div class="sheet-modal investDetailsSheet" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
          <div class="sheet-modal-inner">\
          <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet">\
            <i class="icon f7-icons text-color-gray" style="font-size: 28px;">xmark_circle_fill</i>\
          </a>\
          <h3 class="text-color-blue-light fs-20 fw-600 margin-left">Investment Details</h3>\
          <div class="card card-main card-bs-shadow" style="margin-top: 20px;">\
            <div class="card-content">\
              <div class="list list__loanst">\
                <ul class="color-logo br-10">\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 fw-500"> <b>Reference Number</b> </div>\
                        <div class="item-after text-color-blue-light fs-12 fw-600 ref__ID"></div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Invested Amount</b> </div>\
                        <div class="item-after text-color-blue-light fs-12 fw-600 invest__amount"></div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Daily Returns</b> </div>\
                        <div class="item-after text-color-blue-light fs-12 fw-600">1%</div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Return Days</b> </div>\
                        <div class="item-after text-color-blue-light fs-12 fw-600 text-align-right">Monday to Friday </br>(no weekends or holidays)</div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Daily Withdrawal Limit</b> </div>\
                        <div class="item-after text-color-blue-light fs-12 fw-600">₹2 lakhs</div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Payout Date</b> </div>\
                        <div class="item-after text-color-blue-light fs-12 fw-600 payout-date"></div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Renewal Charges</b> </div>\
                        <div class="item-after text-color-blue-light fs-12 fw-600 renewal__amount"></div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-blue-light fs-12 f-500"> <b>Status</b> </div>\
                        <div class="item-after text-color-blue-light fs-12 fw-600 investSTbadge"><span class="badge badge-ptf bg-color-red-deep text-color-white btn-shine-cm">Inactive!</span></div>\
                      </div>\
                    </div>\
                  </li>\
                </ul>\
                <ul class="extraLi"></ul>\
              </div>\
            </div>\
          </div>\
          </div>\
          </div>',
          closeByBackdropClick: true,
          swipeToClose: true,
          // Events
          on: {
            open: function (sheet) {
              //get investment details
              app.dialog.progress('Loading...');
              app.methods.getUserDataInvest(
                function(data) {
                  app.dialog.close();
                  if(data.isInvested == true) {
                    const investmentData = data.investmentData.find(element => element.refID === refID);
                    //check if investment is present is the array
                    if(investmentData) {
                      $$('.ref__ID').html(investmentData.refID);
                      $$('.invest__amount').html(formatter.format(investmentData.amount));
                      $$('.renewal__amount').html('₹'+investmentData.renewalAmount+' monthly');
                      $$('.payout-date').html(investmentData.payout_date);
                      var extraLi = '';
                      if(investmentData.status == '2') {
                        //canceled
                        $$('.investSTbadge').empty();
                        $$('.investSTbadge').append('<span class="badge badge-ptf bg-color-blue text-color-white btn-shine-cm">Closed</span>');
                      }
                      if(investmentData.status == '1') {
                        $$('.investSTbadge').empty();
                        $$('.investSTbadge').append('<span class="badge badge-ptf bg-color-green-light text-color-green btn-shine-cm">Active</span>');
                        var extraLi ='<li>\
                          <div class="item-content">\
                            <div class="item-inner">\
                              <div class="item-title text-color-blue-light fs-12 f-500"> <b>Activation Date</b> </div>\
                              <div class="item-after text-color-blue-light fs-12 fw-600">'+investmentData.startDate+'</div>\
                            </div>\
                          </div>\
                        </li>\
                        <li>\
                          <div class="item-content">\
                            <div class="item-inner">\
                              <div class="item-title text-color-blue-light fs-12 f-500"> <b>Closing Date</b> </div>\
                              <div class="item-after text-color-blue-light fs-12 fw-600">'+investmentData.closeDate+'</div>\
                            </div>\
                          </div>\
                        </li>';
                      }
                      $$('.extraLi').empty();
                      $$('.extraLi').append(extraLi);
                    } else {
                      app.views.main.router.navigate({name: 'Home'});
                    }
                  } else {
                    app.views.main.router.navigate({name: 'Home'});
                  }
                },
                function() {
                  app.dialog.close();
                  app.toast.show({
                    text: 'Something went wrong!',
                    closeTimeout: 3000,
                  });
                }
              );
            },
            opened: function (sheet) {
            },
          }
        });
        // Events also can be assigned on instance later
        investDetailsSheet.on('close', function (sheet) {
          
        });
        investDetailsSheet.on('closed', function (sheet) {
        });
        //investment close sheet modal
        $$('.invest__requestCloser').on('click', function() {  
          //open a sheet modal for payout
          investcloseSheet.open();
        });
        //create a sheet modal for payout
        var investcloseSheet = app.sheet.create({
          content: '<div class="sheet-modal investcloseSheet" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
            <div class="sheet-modal-inner">\
              <div class="block-container text-align-center">\
              <img src="assets/icons/question-mark.png" class="margin-top" width="75"/>\
              <h4 class="text-color-blue-light fs-16 fw-600 margin-left margin-right">Are you sure you want to close the investment?</h4>\
              <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet">\
                <i class="icon f7-icons text-color-gray" style="font-size: 28px;">xmark_circle_fill</i>\
              </a>\
              <p class="text-color-blue-light fs-12 margin-left margin-right">This action is irreversible and will conclude your current investment plan. Please consider the implications before proceeding.</p>\
              <p class="text-color-blue-light fs-12 margin-left margin-right">A 10% deduction is implemented on the invested amount upon the pre-closure of the investment.</p>\
              <p class="text-color-blue-light fs-12 margin-left margin-right">Read <a href="https://phonetouchfinance.in/investment-tc" class="item-link item-content external" target="_system"> T&C </a> before proceeding.</p>\
              <div class="m-auto w-90 pb-15">\
              <a href="#" style="" class="button button-large button-fill bg-color-red-deep text-color-white btn-alt btn__invest--close">Yes! Close Investment</a>\
              <a href="#" style="" class="margin-top button button-large button-fill bg-color-red-light text-color-red sheet-close">No! Keep Investment</a>\
              </div>\
            </div>\
          </div>',
          closeByBackdropClick: true,
          swipeToClose: true,
          // Events
          on: {
            open: function (sheet) {
            },
            opened: function (sheet) {
              //click on btn__invest--close
              $$(document).off('click', '.btn__invest--close');
              $$(document).on('click', '.btn__invest--close' ,function() {
                var uuid = app.methods.getDeviceUUID();
                var formData = new FormData();
                formData.append('closeInvestment', true);
                formData.append('token', localStorage.getItem('authToken'));
                formData.append('refID', refID);
                formData.append('uuid', uuid);
                app.methods.post(
                  baseURL+'investment.php',
                  formData,
                  'json',
                  function() {
                    app.dialog.progress('Processing...');
                  },
                  function(data) {
                    app.dialog.close();
                    if(data.status == 'success') {
                      app.toast.show({
                        text: data.msg,
                        closeTimeout: 3000,
                      });
                      investcloseSheet.close();
                      var view = app.views.current;
                      view.router.navigate('/investment-details/'+refID,{reloadCurrent: true, ignoreCache: true});
                    }
                    else {
                      app.toast.show({
                        text: data.msg,
                        closeTimeout: 3000,
                      });
                    }
                  },
                  function() {
                    app.dialog.close();
                    app.toast.show({
                      text: 'Something went wrong!',
                      closeTimeout: 3000,
                    });
                  }
                );
              });
            },
          }
        });
        // Events also can be assigned on instance later
        investcloseSheet.on('close', function (sheet) {
        });
        investcloseSheet.on('closed', function (sheet) {
        });
      }
    }
  },
  {
    path: '/payment-history/:refID/:txnID',
    component: paymenthistoryPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var txnID = page.route.params.txnID;
        var refID = page.route.params.refID;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if invested or not
        app.methods.getUserDataInvest(
          function(data) {
            app.dialog.close();
            if(data.isInvested == true) {
              //match the refID
              const investmentData = data.investmentData.find(element => element.refID === refID);
              if(investmentData) {
                if(investmentData.payment_data.isPayment == true) {
                  $$('.block__preloader').addClass('hidden');
                  $$('.media-list-payment-history').empty();
                  var payment_history = '';
                  investmentData.payment_data.paymentData.forEach(val => {
                    if(val.type == '1') {
                      var pay__txt = 'Investment and renewal';
                    } 
                    if(val.type == '2') {
                      var pay__txt = 'Renewal for 1 month';
                    }
                    //payment img
                    if(val.status == '0') {
                      var payment_img = 'assets/icons/payment-pending.png';
                    }
                    if(val.status == '1') {
                      var payment_img = 'assets/icons/payment-success.png';
                    }
                    if(val.status == '2') {
                      var payment_img = 'assets/icons/payment-failed.png';
                    } 
                    payment_history += '<li>\
                    <a href="/payment-history/'+refID+'/'+val.txnID+'" class="item-link item-content payment__detailsLink">\
                    <div class="item-media"><img src="'+payment_img+'" width="50"/></div>\
                    <div class="item-inner">\
                      <div class="item-title-row">\
                      <div class="item-title fw-600 text-whitespace">'+pay__txt+'</div>\
                      </div>\
                      <div class="item-subtitle fs-12">'+val.date+'</div>\
                    </div>\
                      <div class="item-after fs-16 fw-600 margin-right">'+formatter.format(val.amount)+'</div>\
                    </a>\
                    </li>';
                  });
                  $$('.media-list-payment-history').append(payment_history);
                } else {
                  $$('.block__preloader').addClass('hidden');
                  $$('.card__payment-history-no').removeClass('hidden');
                }
              } else {
                app.views.main.router.navigate({name: 'Home'});
              }
            } else {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //payment details sheet
        var paymentSheet = app.sheet.create({
          content: '<div class="sheet-modal paymentDetails__sheetModal" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
            <div class="sheet-modal-inner">\
              <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet">\
                <i class="icon f7-icons text-color-gray" style="font-size: 28px;">xmark_circle_fill</i>\
              </a>\
              <div class="block text-align-center">\
              <img class="paymentDetails_img" src="" width="70" class="m-0" />\
              <h4 class="m-0 text-color-blue-light fs-16 fw-500 payemntST__sheet"></h4>\
              <h4 class="m-0 text-color-blue fs-20 fw-600 amount__sheet"></h4>\
              <div class="card card-main" style="margin-top: 50px;">\
                <div class="card-content">\
                  <div class="list list__loanst">\
                    <ul class="color-logo br-10">\
                      <li>\
                        <div class="item-content">\
                          <div class="item-inner">\
                            <div class="item-title text-color-blue-light fs-12 fw-500"> Transaction ID </div>\
                            <div class="item-after text-color-blue-light fs-12 fw-600 txnID__sheet">  </div>\
                          </div>\
                        </div>\
                      </li>\
                      <li>\
                        <div class="item-content">\
                          <div class="item-inner">\
                            <div class="item-title text-color-blue-light fs-12 fw-500"> Payment Status </div>\
                            <div class="item-after fs-12 fw-600 status__sheet">  </div>\
                          </div>\
                        </div>\
                      </li>\
                      <li>\
                        <div class="item-content">\
                          <div class="item-inner">\
                            <div class="item-title text-color-blue-light fs-12 fw-500"> Payment Date </div>\
                            <div class="item-after text-color-blue-light fs-12 fw-600 date__sheet">  </div>\
                          </div>\
                        </div>\
                      </li>\
                      <li>\
                        <div class="item-content">\
                          <div class="item-inner">\
                            <div class="item-title text-color-blue-light fs-12 fw-500"> Payment Method </div>\
                            <div class="item-after text-color-blue-light fs-12 fw-600 method__sheet">  </div>\
                          </div>\
                        </div>\
                      </li>\
                    </ul>\
                  </div>\
                </div>\
              </div>\
              <a href="#" style="text-decoration: underline; text-decoration-style: dashed;" class="is_invoice__lable col fs-14 external button text-color-red bg-color-white margin-top hidden" target="_system">Get PDF Reciept</a>\
              <a href="#" style="text-decoration: underline; text-decoration-style: dashed;" class="retry__payment col fs-14 button text-color-red bg-color-white margin-top hidden">Retry Payment</a>\
              <a href="#" class="margin-top button button-fill button-large button-fill bg-color-red text-color-white btn-alt sheet-close btn-done">Okay, I got it!</a>\
            </div>\
          </div>',
          // Events
          on: {
            open: function (sheet) {
              var uuid = localStorage.getItem('uuid');
              $.post(baseURL+'investment.php', {getTxnDetails:true, txnID:txnID, token:localStorage.getItem('authToken'), uuid:uuid}, function(data) {
                if(data.status == 'success') {
                  //payment status
                  if(data.data.status == '0') {
                    var paymentStatus = 'Payment Pending';
                    var payment__sheet = 'Pending';
                    var paymentStColor = 'text-color-orange';
                    $$('.paymentDetails_img').attr('src', 'assets/icons/payment-pending.png');
                  }
                  if(data.data.status == '1') {
                    var paymentStatus = 'Payment Success';
                    var payment__sheet = 'Success';
                    var paymentStColor = 'text-color-green';
                    $$('.paymentDetails_img').attr('src', 'assets/icons/payment-success.png');
                    //download invoice
                    $$('.is_invoice__lable').removeClass('hidden');
                    $$('.is_invoice__lable').attr('href', 'https://phonetouchfinance.in/payment-invoice/id/'+data.data.txnID);
                  }
                  if(data.data.status == '2') {
                    var paymentStatus = 'Payment Failed';
                    var payment__sheet = 'Failed';
                    var paymentStColor = 'text-color-red-deep';
                    $$('.paymentDetails_img').attr('src', 'assets/icons/payment-failed.png');
                    //retry payment
                    // $$('.retry__payment').removeClass('hidden');
                    // $$('.retry__payment').attr('href', '/payment/investment');
                  }
                  //payment method
                  if(data.data.method == 'manual') {
                    var paymentMethod = 'Bank Transfer';
                  }
                  else {
                    var paymentMethod = data.data.method;
                  }
                  $$('.payemntST__sheet').html(paymentStatus);
                  $$('.amount__sheet').html(formatter.format(data.data.amount));
                  $$('.txnID__sheet').html(data.data.txnID);
                  $$('.status__sheet').html(payment__sheet);
                  $$('.status__sheet').addClass(paymentStColor);
                  $$('.date__sheet').html(data.data.date);
                  $$('.method__sheet').html(paymentMethod);
                } else {
                  app.views.main.router.navigate({name: 'Home'});
                }
              });
            },
            opened: function (sheet) {
            },
          }
        });
        // Events also can be assigned on instance later
        paymentSheet.on('close', function (sheet) {
          var view = app.views.current;
          view.router.back('/payment-history/'+refID+'/all',{force:true});
        });
        paymentSheet.on('closed', function (sheet) {
        });
        //get payment details
        if(txnID != 'all') {
          app.sheet.close('.paymentDetails__sheetModal');
          paymentSheet.open();
          app.dialog.progress('Loading...');
        } else {
          app.sheet.close('.paymentDetails__sheetModal');
        }
      }
    }
  },
  //invest certificate route
  {
    path: '/invest-certificate/:refID',
    component: investcertificatePage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var refID = page.route.params.refID;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if invested or not
        app.dialog.progress('Loading...');
        app.methods.getUserDataInvest(
          function(data) {
            app.dialog.close();
            if(data.isInvested == true) {
              const investmentData = data.investmentData.find(element => element.refID === refID);
              if(investmentData) {
                $$('.investor__name').html(data.userData.name);
                $$('.investor__email').html(data.userData.email);
                $$('.investor__address').html(data.userData.address);
                $$('.invest__date').html(investmentData.startDate);
                $$('.invest__endDate').html(investmentData.closeDate);
                $$('.invest__amount').html(INR2.format(investmentData.amount));
                $$('.invest__renewal').html(INR2.format(investmentData.renewalAmount)+' monthly');
                $$('.invest__refID').html(investmentData.refID);
                $$('.processing-fee').html(investmentData.loanDetails.charges.processingFee);
                $$('.payout_date').html(investmentData.payout_date);
              } else {
                app.views.main.router.navigate({name: 'Home'});
              }
            } else {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //download agreemnet as pdf
        $$('.btn-download__certificate').on('click', function() {
          //add style margin-top: 300px; id page1
          const page1 = $('#page1');
          page1.css('margin-top', '350px');
          //add style margin-top: 300px; id page2
          const page2 = $('#page2');
          page2.css('margin-top', '150px');
          //page3
          const page3 = $('#page3');
          page3.css('margin-top', '150px');
          // Source HTMLElement or a string containing HTML.
          var source = window.document.getElementById("block__certificate");
          //check if cordova
          if (app.device.cordova) {
            //display a toast
            app.toast.show({
              text: 'Please log in to your account on a mobile browser to download the certificate.',
              closeTimeout: 3000,
            });
            // let options = {
            //   documentSize: 'A4',
            //   type: 'share',
            //   fileName: 'Certificate of Investment Agreement.pdf'
            // }
            // pdf.fromData(source.outerHTML, options)
            //   .then((stats)=> console.log(stats), page1.css('margin-top', ''),page2.css('margin-top', ''))   // ok..., ok if it was able to handle the file to the OS.  
            //   .catch((err)=>console.log(err))
          } else {
            var doc = new jsPDF();
            doc.html(source, {
              callback: function(doc) {
                  // Save the PDF
                  doc.save('Certificate of Investment Agreement.pdf');
                  page1.css('margin-top', '');
                  page2.css('margin-top', '');
                  page3.css('margin-top', '');
              },
              compress: true,
              x: 15,
              y: 15,
              width: 170, //target width in the PDF document
              windowWidth: 650 //window width in CSS pixels
            });
          }
        });
      }
    }
  },
  //my transactions route
  {
    path: '/transactions/:refID/:type',
    component: transactionsPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var type = page.route.params.type;
        var refID = page.route.params.refID;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if invested or not
        app.dialog.progress('Loading...');
        app.methods.getUserDataInvest(
          function(data) {
            app.dialog.close();
            //match the refID
            const investmentData = data.investmentData.find(element => element.refID === refID);
            if(investmentData) {
              if(data.isInvested == true) {
                //if status is active
                if(investmentData.status == '1') {
                  //check routes
                  if(type == 'returns' || type == 'all') {
                    $$('.txnh2__txt').html('Return History');
                    app.tab.show('#return');
                    getReturns();
                  } else if(type == 'withdraw') {
                    $$('.txnh2__txt').html('Withdrawal History');
                    app.tab.show('#withdraw');
                    getWithdraw();
                  } else {
                    $$('.txnh2__txt').html('Payout History');
                    app.tab.show('#payout');
                    getPayout();
                  }
                  //switch tab
                  $(".tab" ).on('tab:show', function() {
                    var $tabEl = $(this);
                    var tabId = $tabEl.attr('id');
                    if(tabId == 'withdraw') {
                      $$('.txnh2__txt').html('Withdrawal History');
                      getWithdraw();
                    } else if(tabId == 'return') {
                      $$('.txnh2__txt').html('Return History');
                      getReturns();
                    } else {
                      $$('.txnh2__txt').html('Payout History');
                      getPayout();
                    }
                  });
                } else {
                  setTimeout(() => {
                    //back to dashboard
                    var view = app.views.current;
                    view.router.back('/investment-dashboard/');
                  }, 1000);
                }
              } else {
                app.views.main.router.navigate({name: 'Home'});
              }
            } else {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        function getReturns() {
          $$('.media-list-returns').empty();
          //get returns data from api
          $.post(baseURL+'investment.php', {getReturns:true, token:localStorage.getItem('authToken'), uuid:app.methods.getDeviceUUID(), refID:refID}, function(data) {
            if(data.isReturns == false) {
              $$('.block__preloader__return').addClass('hidden');
              $$('.card__returns').removeClass('hidden');
            } else {
              //if returns is available
              $$('.block__preloader__return').addClass('hidden');
              $$('.media-list-returns').empty();
              var return_history = '';
              data.data.forEach(val => {
                if(val.type == '2') {
                  var return_type = '<i class="f7-icons text-color-red-deep">arrow_up_right</i>';
                  var return_type_color = 'text-color-red-deep';
                  var amount = '- '+formatter.format(val.amount);
                  var txt = 'Automatic Renewal';
                }
                else if(val.type == '3') {
                  var return_type = '<i class="f7-icons text-color-green">arrow_down_right</i>';
                  var return_type_color = 'text-color-green';
                  var amount = '+ '+formatter.format(val.amount);
                  var txt = 'Return Received';
                } else {
                  var return_type = '<i class="f7-icons text-color-red-deep">arrow_up_right</i>';
                  var return_type_color = 'text-color-red-deep';
                  var amount = '- '+formatter.format(val.amount);
                  var txt = 'Payout Released';
                }
                return_history += '<li>\
                <a href="#" class="item-link item-content">\
                <div class="item-media"><i class="f7-icons text-color-green">'+return_type+'</i></div>\
                <div class="item-inner">\
                  <div class="item-title-row">\
                  <div class="item-title fw-600">'+txt+'</div>\
                  </div>\
                  <div class="item-subtitle fs-12 text-whitespace">Closing Balance: '+formatter.format(val.updatedBalance)+'</div>\
                  <div class="item-subtitle fs-12">'+val.date+'</div>\
                </div>\
                  <div class="item-after fs-16 fw-600 margin-right '+return_type_color+'">'+amount+'</div>\
                </a>\
                </li>';
              });
              $$('.media-list-returns').append(return_history);
            }
          });
        }
        function getWithdraw() {
          $$('.media-list-withdraw').empty();
          //get withdraw data from api
          $.post(baseURL+'investment.php', {getWithdraw:true, token:localStorage.getItem('authToken'), uuid:app.methods.getDeviceUUID(), refID:refID}, function(data) {
            if(data.isWithdraw == false) {
              $$('.block__preloader_withdraw').addClass('hidden');
              $$('.card__withdraw').removeClass('hidden');
            } else {
              //if withdraw is available
              $$('.block__preloader_withdraw').addClass('hidden');
              $$('.media-list-withdraw').empty();
              var withdraw_history = '';
              data.data.forEach(val => {
                if(val.status == '0') {
                  var statusText = '<span class="badge badge-ptf bg-color-orange text-color-white btn-shine-cm badge-ptf-comingsoon ml-0">Pending</span>';
                  var statusText2 = '<div class="item-subtitle fs-12 fw-600 text-color-orange">Pending</div>';
                }
                if(val.status == '1') {
                  var statusText = '<span class="badge badge-ptf bg-color-green text-color-white btn-shine-cm badge-ptf-comingsoon ml-0">Success</span>';
                  var statusText2 = '<div class="item-subtitle fs-12 fw-600 text-color-green">Success</div>';
                }
                if(val.status == '2') {
                  var statusText = '<span class="badge badge-ptf bg-color-red-deep text-color-white btn-shine-cm badge-ptf-comingsoon ml-0">Failed</span>';
                  var statusText2 = '<div class="item-subtitle fs-12 fw-600 text-color-red-deep">Failed</div>';
                }
                withdraw_history += '<li>\
                <a href="#" class="item-link item-content">\
                <div class="item-media"><i class="f7-icons text-color-red-deep">arrow_up_right</i></div>\
                <div class="item-inner">\
                  <div class="item-title-row">\
                  <div class="item-title fw-600 text-whitespace">Money Transfered to '+val.bankName+' xxxx'+val.bankAC+'</div>\
                  </div>\
                  <div class="item-subtitle fs-12 text-whitespace">Closing Balance: '+formatter.format(val.updatedBalance)+'</div>\
                  <div class="item-subtitle fs-12">'+val.date+'</div>\
                  '+statusText+'\
                </div>\
                  <div class="item-after fs-16 fw-600 margin-right text-color-red-deep">- '+formatter.format(val.amount)+'</div>\
                </a>\
                </li>';
              });
              $$('.media-list-withdraw').append(withdraw_history);
            }
          });
        }
        function getPayout() {
          $$('.media-list-payout').empty();
          //get payout data from api using getJSON method
          $.post(baseURL+'investment.php', {getPayout:true, token:localStorage.getItem('authToken'), uuid:app.methods.getDeviceUUID(), refID:refID}, function(data) {
            if(data.isPayout == false) {
              $$('.block__preloader_payout').addClass('hidden');
              $$('.card__payout').removeClass('hidden');
            } else {
              //if payout is available
              $$('.block__preloader_payout').addClass('hidden');
              $$('.media-list-payout').empty();
              var payout_history = '';
              data.data.forEach(val => {
                if(val.type == '4') {
                  var title = 'Payout Recieved';
                } else {
                  var title = 'Refund Recieved';
                }
                payout_history += '<li>\
                <a href="#" class="item-link item-content">\
                <div class="item-media"><i class="f7-icons text-color-green">arrow_down_right</i></div>\
                <div class="item-inner">\
                  <div class="item-title-row">\
                  <div class="item-title fw-600">'+title+'</div>\
                  </div>\
                  <div class="item-subtitle fs-12 text-whitespace">Closing Balance: '+formatter.format(val.updatedBalance)+'</div>\
                  <div class="item-subtitle fs-12">'+val.date+'</div>\
                </div>\
                  <div class="item-after fs-16 fw-600 margin-right text-color-green">+ '+formatter.format(val.amount)+'</div>\
                </a>\
                </li>';
              });
              $$('.media-list-payout').append(payout_history);
            }
          });
        }
      }
    }
  },
  //wallet route
  {
    path: '/wallet/:refID',
    component: walletPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var refID = page.route.params.refID;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if invested or not
        app.dialog.progress('Loading...');
        app.methods.getUserDataInvest(
          function(data) {
            app.dialog.close();
            if(data.isInvested == true) {
              //match the refID
              const investmentData = data.investmentData.find(element => element.refID === refID);
              //if staus is active
              if(investmentData.status == '1') {
                $$('.withdrawable__balance').html(formatter.format(investmentData.withdraw_bal));
                if(investmentData.payout_data.lastPayout == null && investmentData.payout_data.lastPayoutDate == null) {
                  $$('.withdrawable__text').html('Next payout will be on '+investmentData.payout_data.nextPayoutDate);
                } else {
                  $$('.withdrawable__text').html(formatter.format(investmentData.payout_data.lastPayout)+' credited on '+investmentData.payout_data.lastPayoutDate);
                  $$('.withdrawable__link').attr('href', '/withdraw/'+refID);
                }
                $$('.return__balance').html(formatter.format(investmentData.returnWallet));
                if(investmentData.lastReturn == null && investmentData.lastReturnDate == null) {
                  $$('.return__text').html('No returns received yet');
                } else {
                  $$('.return__text').html(formatter.format(investmentData.lastReturn)+' credited on '+investmentData.lastReturnDate);
                  $$('.return__link').attr('href', '/transactions/'+refID+'/returns');
                }
              } else {
                app.views.main.router.navigate({name: 'Home'});
              }
            } else {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
      }
    }
  },
  //bank route
  {
    path: '/bank/',
    component: bankPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var type = page.route.params.type;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //api status
        app.methods.getAPI(function(data) {
          data.forEach(val => {
            if(val.name == 'bank' && val.value == '0') {
              $$('.item-bank-verify').empty();
              $$('.block-error-txt-bank').empty();
              $$('.block-error-txt-bank').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>Our Bank verification system is currently undergoing maintenance. Please revisit at a later time. We apologize for any inconvenience this may cause.</span>');
              //enable manual procedure
              $$('.block__kyc__manual').removeClass('hidden');
            }
            if(val.name == 'bank' && val.value == '1') {
              $$('.list-bank-verify').removeClass('hidden');
            }
          });
        });
        app.methods.getUserData(function(userData) {
          if(userData.isLoggedin == 'true') {
            $$('.profile-bankac').html(userData.ac);
            $$('.profile-bank').html(userData.bname);
            if(userData.kycData[0].is_bank == '1') {
              $$('.block__kyc__manual').addClass('hidden');
              $$('.block-error-txt-bank').empty();
              $$('.bank_li__kyc').addClass('hidden');
              $$('.item-bank-verify').empty();
              $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-green-light text-color-green"><i class="icon f7-icons text-color-green" style="font-size: 16px; right: 5px;">checkmark_alt_circle_fill</i> Verified</span>');
            }
            if(userData.kycData[0].is_fail_bank == 'name_not_matched' && userData.kycData[0].is_bank == 0) {
              $$('.item-bank-verify').empty();
              $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Update name</span>');
              $$('.block-error-txt-bank').empty();
              $$('.block-error-txt-bank').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>Kindly update your name in the profile settings to match your Bank account details. Once updated, please return to complete your Bank verification process.</span>');
            }
            if(userData.kycData[0].is_fail_bank == 'error_invalid_bank' && userData.kycData[0].is_bank == 0) {
              $$('.item-bank-verify').empty();
              $$('.item-bank-verify').append('<span class="badge badge-ptf bg-color-red-deep-light text-color-red-deep">Invalid Bank Account</span>');
              $$('.block-error-txt-bank').empty();
              $$('.block-error-txt-bank').append('<i class="icon f7-icons" style="font-size: 16px; right: 5px;">info_circle</i>\
                <span>We kindly request you to contact us for updating your Bank Account information along with the required proof. After the update, please return at your convenience to complete the Bank verification process.</span>');
            }
            if(userData.kycData[0].bank_img != null && userData.kycData[0].is_bank == null) {
              $$('.kyc__bankManual_txt').removeClass('hidden');
              $$('.kyc__bankManual_txt').html('Your bank verification is under review. Please check back later.');
            }
          } else {
            app.views.main.router.navigate({name: 'Home'});
          }
        });
        //bank image upload
        $$('#bankImg').on('change', function() {
          var bankImg = $$('#bankImg').prop('files')[0];
          var formData = new FormData();
          formData.append('bankUpload', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('bankImg', bankImg);
          formData.append('uuid', app.methods.getDeviceUUID());
          var url = baseURL+'investment.php';
          var file = this.files[0];
          var fileType = file['type'];
          //image format jpeg, jpg, png small and caps
          var validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/JPEG", "image/PNG", "image/JPG"];
          
          if(!$$('#bankImg')[0].checkValidity()) {
            app.toast.show({
              text: 'Please upload your bank passbook or cheque!',
              closeTimeout: 3000,
            });
          }
          else if ($.inArray(fileType, validImageTypes) < 0) {
            app.toast.show({
              text: 'This is not an Image!',
              closeTimeout: 3000,
            });
          }
          else {
            //upload
            app.methods.post(
              url,
              formData,
              'json',
              function() {
                app.dialog.progress('Uploading...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  app.toast.show({
                    text: data.msg,
                    closeTimeout: 3000,
                  });
                  var view = app.views.current;
                  view.router.back('/investment-dashboard/',{force:true});
                }
                else {
                  app.toast.show({
                    text: data.status,
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                app.dialog.close();
                app.toast.show({
                text: 'Something went wrong!',
                closeTimeout: 3000,
              });
              }
            );
          }
        });
      }
    }
  },
  //withdraw route
  {
    path: '/withdraw/:refID',
    component: withdrawPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var refID = page.route.params.refID;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if invested or not
        app.dialog.progress('Loading...');
        app.methods.getUserDataInvest(
          function(data) {
            app.dialog.close();
            if(data.isInvested == true) {
              //match the refID
              const investmentData = data.investmentData.find(element => element.refID === refID);
              //if staus is active
              if(investmentData.status == '1') {
                $$('.avl-bal').html(formatter.format(investmentData.withdraw_bal));
                //remove desicmal point from lastPayout
                $$('.total-payout').html(formatter.format(investmentData.payout_data.totalPayout));
                if(investmentData.payout_data.lastPayout == null && investmentData.payout_data.lastPayoutDate == null) {
                  $$('.last-return').html('₹0.00');
                  $$('.last-return-date').html('No payouts');
                } else {
                  $$('.last-return').html(formatter.format(investmentData.payout_data.lastPayout));
                  $$('.last-return-date').html(investmentData.payout_data.lastPayoutDate);
                  $$('.link__payout').attr('href', '/transactions/'+refID+'/payout');
                }
                //total withdraw,last withdraw, last date
                $$('.link__withdraw').attr('href', '/transactions/'+refID+'/withdraw');
                $$('.total-withdraw').html(formatter.format(investmentData.withdraw_data.totalWithdraw));
                if(investmentData.withdraw_data.lastWithdraw == null && investmentData.withdraw_data.lastWithdrawDate == null) {
                  $$('.last-withdraw').html('₹0.00');
                  $$('.last-withdraw-date').html('No withdrawals');
                } else {
                  $$('.last-withdraw').html(formatter.format(investmentData.withdraw_data.lastWithdraw));
                  $$('.last-withdraw-date').html(investmentData.withdraw_data.lastWithdrawDate);
                }
                //next payout details
                $$('.next-return').html(formatter.format(investmentData.returnWallet));
                $$('.next-return-date').html(investmentData.payout_data.nextPayoutDate);
                if(investmentData.autoWithdrawal == '1') {
                  $$('.withdraw__type').removeClass('hidden');
                  $$('.withdraw__type').html('Automatic Withdrawal');
                  $$('input[name="withdrawType"]').prop('checked', true);
                } else {
                  $$('.withdraw__type').removeClass('hidden');
                  $$('.withdraw__type').html('Manual Withdrawal');
                  $$('input[name="withdrawType"]').prop('checked', false);
                }
              } else {
                app.views.main.router.navigate({name: 'Home'});
              }
            } else {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //show auto withdraw sheet modal
        $$('.showWithdrawInfo').on('click', function() {
          autoWithdrawSheet.open();
        });
        //create a sheet modal for auto withdraw
        var autoWithdrawSheet = app.sheet.create({
          content: '<div class="sheet-modal autoWithdraw--Sheet" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
            <div class="sheet-modal-inner">\
              <div class="block-container">\
              <h4 class="text-color-blue-light fs-16 fw-600 margin-left margin-right">Choose your withdrawal method</h4>\
              <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet">\
                <i class="icon f7-icons text-color-gray" style="font-size: 30px;">xmark_circle_fill</i>\
              </a>\
              <p class="text-color-blue-light fs-12 margin-left margin-right renewal__sheet--text info-ptf" style="padding-top: 0">\
              <br/><br/><span class="fs-14 fw-600">How automatic withdrawal works:</span> \
              <br/>1. Your payout will be automatically credited to your bank account on the payout date by 24 hours.\
              <br/>2. You no longer have to manually request for a payout.\
              <br/><br/><span class="fs-14 fw-600">How manual withdrawal works:</span> \
              <br/>1. You have to manually request for a payout.\
              <br/>2. Your payout will be credited to your bank instantly or may take up to 24 hours.\
              </p>\
            </div>\
          </div>',
          closeByBackdropClick: true,
          // Events
          on: {
            open: function (sheet) {
            },
            opened: function (sheet) {
            },
          }
        });
        //check if toggle is clicked
        var toggle = app.toggle.create({
          el: '.toggle-withdraw',
          on: {
            change: function () {
              //send request to change renewal type
              var formData = {
                autoWithdrawal: true,
                token: localStorage.getItem('authToken'),
                refID: refID,
                uuid: app.methods.getDeviceUUID(),
              };
              app.methods.request(
                baseURL+'/investment.php',
                formData,
                'json',
                function() {
                  app.dialog.progress('Loading...');
                },
                function(data) {
                  app.dialog.close();
                  if(data.status == 'success') {
                    app.toast.show({
                      text: data.msg,
                      closeTimeout: 3000,
                    });
                    $$('.withdraw__type').html(data.type);
                  }
                  else {
                    app.toast.show({
                      text: data.msg,
                      closeTimeout: 3000,
                    });
                  }
                },
                function() {
                  app.dialog.close();
                  app.toast.show({
                    text: 'Something went wrong!',
                    closeTimeout: 3000,
                  });
                }
              );
            }
          }
        })
        //make a payout request on button click
        $$('.btn-payout').on('click', function() {  
          //open a sheet modal for payout
          payoutSheet.open();
        });
        //create a sheet modal for payout
        var payoutSheet = app.sheet.create({
          content: '<div class="sheet-modal payout__sheetModal" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
            <div class="sheet-modal-inner">\
              <div class="block">\
              <h4 class="text-color-blue-light fs-16 fw-600 margin-left">Make a withdrawal request</h4>\
              <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet--payout">\
                <i class="icon f7-icons text-color-gray" style="font-size: 30px;">xmark_circle_fill</i>\
              </a>\
              <div class="list no-hairlines-md">\
                <ul>\
                  <li class="item-content item-input">\
                    <div class="item-inner">\
                      <div class="item-input-wrap">\
                        <input type="hidden" name="payoutAmount" id="payoutAmount" pattern="[0-9]*" validate required />\
                        <input type="hidden" name="payoutURL" id="payoutURL" validate required />\
                        <input type="text" class="payoutAmount" placeholder="Enter amount" validate required data-error-message="" />\
                      </div>\
                    </div>\
                  </li>\
                  <h3 class="text-color-blue margin-left fs-16 display-flex"><span>Choose your Bank</span></h3>\
                  <li>\
                    <label class="item-radio item-content">\
                        <input type="radio" name="bankID" value="0" checked />\
                        <i class="icon icon-radio"></i>\
                        <div class="item-inner">\
                        <div class="item-title fw-600 bank_name text-whitespace"></div>\
                        <div class="item-after"><span class="badge badge-ptf bg-color-red text-color-white btn-shine">Primary</span></div>\
                        </div>\
                    </label>\
                  </li>\
                </ul>\
              </div>\
              <a href="#" class="margin-top button button-fill button-large button-fill bg-color-red text-color-white btn-alt btn-payout__done">Make request</a>\
            </div>\
          </div>',
          closeByBackdropClick: true,
          // Events
          on: {
            open: function (sheet) {
              //check api status
              app.methods.getAPI(function(data) {
                data.forEach(val => {
                  if(val.name == 'razorpayX_payout' && val.value == '0') {
                    $$('#payoutURL').val(baseURL+'investment.php');
                  }
                  if(val.name == 'razorpayX_payout' && val.value == '1') {
                    $$('#payoutURL').val('https://rellancerinfotech.com/api/payouts/');
                  }
                });
              });
              //get investment details
              app.methods.getUserDataInvest(
                function(data) {
                  if(data.isInvested == true) {
                    //match the refID
                    const investmentData = data.investmentData.find(element => element.refID === refID);
                    $$('.payoutAmount').attr('data-max', investmentData.withdraw_bal);
                    $$('.payoutAmount').attr('data-min', '500');
                    $$('.payoutAmount').attr('data-error-message', 'Please enter an amount between ₹500 and '+INR.format(investmentData.withdraw_bal));
                    $$('.bank_name').html(data.bankData.bankName+' - <span class="fs-12">'+data.bankData.bankAC+'</span>');
                    //amount masking
                    $$('input.payoutAmount').keyup(function(event) {
                      var val = $(this).val().replace(/[₹ ,]/g,'');
                      $$('input#payoutAmount').val(val);
                      // skip for arrow keys
                      if(event.which >= 37 && event.which <= 40){
                      event.preventDefault();
                      }
                      $(this).val(function(index, value) {
                          value = value.replace(/,/g,'');
                          if(value.indexOf('₹') > -1) {
                            return app.methods.addComma(value);
                          }
                          else {
                            return '₹ '+app.methods.addComma(value);
                          }
                      });
                    });
                  }
                }
              );
              //make a payout request on button click
              $$(document).off('click', '.btn-payout__done');
              $$(document).on('click', '.btn-payout__done', function() {
                if (!$$('.payoutAmount')[0].checkValidity()) {
                  console.log('Check Validity!');
                } else {
                  //check if amount is minimum 500 and maximum withdrawable balance
                  var maximum = $$('.payoutAmount').attr('data-max');
                  //remove .00 from maximum
                  maximum = maximum.replace('.00', '');
                  //to number
                  maximum = parseInt(maximum);
                  var minimum = $$('.payoutAmount').attr('data-min');
                  minimum = parseInt(minimum);
                  var amount = $$('#payoutAmount').val();
                  amount = parseInt(amount);
                  if(maximum < minimum) {
                    app.toast.show({
                      text: 'You have insufficient balance in your withdrawable wallet!',
                      closeTimeout: 3000,
                    });
                  }
                  else if(amount < minimum || maximum < amount) {
                    app.toast.show({
                      text: 'Please enter an amount between ₹500 and '+INR.format(maximum),
                      closeTimeout: 3000,
                    });
                  }
                  //check if amount is not in decimal
                  else if(amount % 1 != 0) {
                    app.toast.show({
                      text: 'Please enter a valid amount!',
                      closeTimeout: 3000,
                    });
                  }
                  else {
                    //input validation
                    var formData = new FormData();
                    formData.append('payout', true);
                    formData.append('token', localStorage.getItem('authToken'));
                    formData.append('amount', $$('#payoutAmount').val());
                    formData.append('bankID', $$('.item-radio input[name="bankID"]:checked').val());
                    formData.append('uuid', app.methods.getDeviceUUID());
                    formData.append('refID', refID);
                    var url = $$('#payoutURL').val();
                    app.methods.post(
                      url,
                      formData,
                      'json',
                      function() {
                        app.dialog.progress('Loading...');
                      },
                      function(data) {
                        app.dialog.close();
                        if(data.status == 'success') {
                          app.toast.show({
                            text: data.msg,
                            closeTimeout: 3000,
                          });
                          //close sheet modal
                          payoutSheet.close();
                          var view = app.views.current;
                          view.router.navigate('/transactions/'+refID+'/withdraw/',{reloadCurrent: true, ignoreCache: true});
                        }
                        else {
                          app.toast.show({
                            text: data.msg,
                            closeTimeout: 3000,
                          });
                        }
                      },
                      function() {
                        app.dialog.close();
                        app.toast.show({
                        text: 'Something went wrong!',
                        closeTimeout: 3000,
                      });
                      }
                    );
                  }
                }
              });
            },
            opened: function (sheet) {
            },
          }
        });
        // Events also can be assigned on instance later
        payoutSheet.on('close', function (sheet) {
        });
        payoutSheet.on('closed', function (sheet) {
        });
      }
    }
  },
  //renewal route
  {
    path: '/renewal-details/:refID',
    component: renewalPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var refID = page.route.params.refID;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'false' && data.status == 'blocked') {
              app.views.main.router.navigate({name: 'Home'});
            }
            if(data.isLoggedin == 'false') { //trun off for the demo purpose
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if invested or not
        app.dialog.progress('Loading...');
        app.methods.getUserDataInvest(
          function(data) {
            app.dialog.close();
            if(data.isInvested == true) {
              //match refID
              const investmentData = data.investmentData.find(element => element.refID === refID);
              if(investmentData) {
                //if staus is active
                if(investmentData.status == '1') {
                  if(investmentData.isRenew == 0 && investmentData.renewalDate == null) {
                    $$('.renwal__days').html('0');
                  }
                  if(investmentData.isRenew == 1 && investmentData.renewalDate != null) {
                    $$('.renwal__days').html(investmentData.renewalDaysLeft);
                  }
                  //if renewal is expired
                  if(investmentData.isRenew == 0 && investmentData.renewalDate != null) {
                    $$('.renwal__days').html('0');
                    $$('.btn-is__renewal').removeClass('disabled');
                    $$('.btn-is__renewal').attr('href', '/payment/renewal/'+refID);
                  }
                  if(investmentData.isRenew == 1 && investmentData.renewalDate != null && investmentData.renewalDaysLeft == 0) {
                    $$('.renwal__days').html('0');
                    $$('.btn-is__renewal').removeClass('disabled');
                    $$('.btn-is__renewal').attr('href', '/payment/renewal/'+refID);
                  }
                  //renewal type
                  if(investmentData.renewalType == 'manual') {
                    $$('.renewal__type').removeClass('hidden');
                    $$('.renewal__type').html('Manual Renewal');
                    $$('.change__renewal--link').attr('data-renewal', 'manual');
                  } else {
                    $$('.renewal__type').removeClass('hidden');
                    $$('.renewal__type').html('Automatic Renewal');
                    $$('.change__renewal--link').attr('data-renewal', 'auto');
                    $$('input[name="renewalType"]').prop('checked', true);
                  }
                } else {
                  app.views.main.router.navigate({name: 'Home'});
                }
              } else {
                app.views.main.router.navigate({name: 'Home'});
              }
            } else {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        //check if toggle is clicked
        var toggle = app.toggle.create({
          el: '.toggle-renewal',
          on: {
            change: function () {
              //send request to change renewal type
              var formData = {
                changeRenewalType: true,
                token: localStorage.getItem('authToken'),
                refID: refID,
                uuid: app.methods.getDeviceUUID(),
              };
              app.methods.request(
                baseURL+'/investment.php',
                formData,
                'json',
                function() {
                  app.dialog.progress('Loading...');
                },
                function(data) {
                  app.dialog.close();
                  if(data.status == 'success') {
                    app.toast.show({
                      text: data.msg,
                      closeTimeout: 3000,
                    });
                    $$('.renewal__type').html(data.type);
                  }
                  else {
                    app.toast.show({
                      text: data.msg,
                      closeTimeout: 3000,
                    });
                  }
                },
                function() {
                  app.dialog.close();
                  app.toast.show({
                    text: 'Something went wrong!',
                    closeTimeout: 3000,
                  });
                }
              );
            }
          }
        })
        //show the renewal sheet modal
        $$('.showRenewalInfo').on('click', function() {
          //open a sheet modal for renewal
          renewalSheet.open();
        });
        //create a sheet modal for payout
        var renewalSheet = app.sheet.create({
          content: '<div class="sheet-modal renewalSheet" style="height: auto;border-top-right-radius: 20px;border-top-left-radius: 20px;">\
            <div class="sheet-modal-inner">\
              <div class="block-container">\
              <h4 class="text-color-blue-light fs-16 fw-600 margin-left margin-right">How the renewal system works?</h4>\
              <a href="#" class="link sheet-close ripple-color-red float-right custom__close--sheet">\
                <i class="icon f7-icons text-color-gray" style="font-size: 30px;">xmark_circle_fill</i>\
              </a>\
              <p class="text-color-blue-light fs-12 margin-left margin-right renewal__sheet--text info-ptf" style="padding-top: 0">\
              <br/><br/><span class="fs-14 fw-600">How the automatic renewal works:</span> \
              <br/>1. Your investment will be renewed automatically every month.\
              <br/>2. You will receive a notification 5 days before the renewal date.\
              <br/>3. You will receive a notification on the renewal date.\
              <br/>4. If you have sufficient balance in your wallet, the renewal will be successful.\
              <br/>5. If you do not have sufficient balance in your wallet, the renewal will be unsuccessful.\
              <br/>6. If the renewal is unsuccessful, you will have to manually renew your investment.\
              <br/><br/><span class="fs-14 fw-600">How the manual renewal works:</span> \
              <br/>1. You will receive a notification 5 days before the renewal date.\
              <br/>2. You will receive a notification on the renewal date.\
              <br/>3. You will have to manually renew your investment.\
              <br/>4. If you do not renew your investment, it will expire and you will not receive any returns.\
              </p>\
            </div>\
          </div>',
          closeByBackdropClick: true,
          // Events
          on: {
            open: function (sheet) {
            },
            opened: function (sheet) {
            },
          }
        });
        // Events also can be assigned on instance later
        renewalSheet.on('close', function (sheet) {
        });
        renewalSheet.on('closed', function (sheet) {
        });
      }
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
    on: {
      pageInit: function() {
        $$('.url').html(window.location);
      }
    }
  },
];

export default routes;